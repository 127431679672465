import { FunctionComponent, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { pages } from "pages";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { getNumberParam } from "helpers/views";
import { useCreateTagMutation, useGetTagQuery, useUpdateTagMutation } from "../../../../store/apis/tag";
import { errorString } from "../../../../helpers/api";
import TagForm, { TagFormSubmit } from "./TagForm";
import { Box } from "@mui/material";


type TagPageParams = {
	tagId: string;
}

const TagPage: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ 
	workspaceId,
	intl
}) => {
	const params = useParams<TagPageParams>();
	const [ tagId, paramError ] = getNumberParam(params.tagId, "tagId", intl);

	
	const navigate = useNavigate();

	const { data: tag, isLoading, error } = useGetTagQuery({  workspaceId, tagId: tagId || 0 }, {
		skip: !tagId
	});
	const [ updateTag,
		{
			isSuccess: isTagUpdateSuccess,
			isLoading: isTagUpdateInProgress,
			error: updateError,
		}
	] = useUpdateTagMutation();
	const [ createTag,
		{
			isSuccess: isTagCreateSuccess,
			isLoading: isTagCreateInProgress,
			error: createError,
		}
	] = useCreateTagMutation();

	const onSubmit: TagFormSubmit = async ({
		name,
		font_color,
		background_color,
		group
	}) => {
		const data = {
			name: name,
			background_color: background_color.toUpperCase(),
			font_color: font_color.toUpperCase(),
			group: group || "",
			is_system: false
		};

		if (tagId) {
			updateTag({
				workspaceId: workspaceId,
				tagId,
				data
			});
		} else {
			createTag({
				workspaceId: workspaceId,
				data
			});
		}

	};

	useEffect(() => {
		if (isTagUpdateSuccess || isTagCreateSuccess) {
			navigate(pages.workspace.tags.url(workspaceId));
		}
	}, [ isTagUpdateSuccess, isTagCreateSuccess, navigate, workspaceId ]);

	return (
		<ContentPage
			title={
				tagId
					? intl.formatMessage({ id: "app.editTag" })
					: intl.formatMessage({ id: "app.addTag" })
			}
			closeLink={pages.workspace.tags.url(workspaceId)}
			loading={isLoading}
			error={errorString(intl, paramError || error)}
		>
			<Box component="div" sx={{ flexGrow: 4, mx: "auto", mt: 2, maxWidth: "700px" }}>
				<TagForm 
					tag={tag}
					onSubmit={onSubmit}
					inProgress={isTagUpdateInProgress || isTagCreateInProgress}
					closeLink={pages.workspace.tags.url(workspaceId)}
					error={createError || updateError}
				/>
			</Box>
		</ContentPage>
	);
};

export default injectIntl(TagPage);

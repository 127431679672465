import { FunctionComponent, useEffect, useState } from "react";
import {
	Divider,
	FormControl,
	MenuItem,
	Select,
	Typography
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { shareAccessLevel } from "consts";
import { toast } from "react-toastify";
import ShareLinkForm from "./ShareLinkForm/ShareLinkForm";
import ShareLinkList from "./ShareLinkList/ShareLinkList";
import SimpleDialog from "components/common/SimpleDialog/SimpleDialog";
import { AccessLevel } from "@s6e/spicify-api-sdk-js";
import { styled } from "@mui/material/styles";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import {
	useDeleteShareLinkMutation,
	useGetShareLinksQuery,
	useUpdateShareLinkMutation
} from "../../../../store/apis/shareLink";
import { errorString } from "../../../../helpers/api";
import ContentLoader from "components/common/ContentPage/ContentLoader";

const ContentWrapper = styled("div")(({ theme }) => ({
	marginLeft: "1rem",
	[theme.breakpoints.down("md")]: {
		marginLeft: 0
	}
}));

const ShareLink: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({
	workspaceId,
	intl,
}) => {
	const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false);
	const [isEditDialogOpened, setEditDialogOpened] = useState(false);
	const [chosenLinkId, setChosenLinkId] = useState<number | null>(null);
	const [newAccessLevel, setNewAccessLevel] = useState<AccessLevel | null>(
		null
	);
	const { data: shareLinks, isLoading, error } = useGetShareLinksQuery(workspaceId);
	const [
		deleteShareLink,
		{
			isSuccess: isShareLinkDeleteSuccess,
			isError: isShareLinkDeleteError,
			error: shareLinkDeleteError,
			isLoading: isShareLinkDeleteInProgress
		}
	] = useDeleteShareLinkMutation()
	const [
		updateShareLink,
		{
			isSuccess: isShareLinkUpdateSuccess,
			isError: isShareLinkUpdateError,
			error: shareLinkUpdateError,
			isLoading: isShareLinkUpdateInProgress
		}
	] = useUpdateShareLinkMutation()

	const onShareLinkDelete = async () => {
		if (chosenLinkId) {
			deleteShareLink({
				workspaceId,
				shareLinkId: chosenLinkId,
			});
		}
	};

	useEffect(() => {
		if (isShareLinkDeleteSuccess || isShareLinkDeleteError) {
			closeDeleteDialog();
		}
	}, [ isShareLinkDeleteSuccess, isShareLinkDeleteError ] );

	useEffect(() => {
		if (shareLinkDeleteError) {
			toast.error(errorString(intl, shareLinkDeleteError));
		}
	}, [ shareLinkDeleteError, intl ]);

	const editLink = async () => {
		if (chosenLinkId && newAccessLevel) {
			updateShareLink({
				workspaceId,
				shareLinkId: chosenLinkId,
				data: {
					access_level: newAccessLevel,
				},
			});
		}
	};

	useEffect(() => {
		if (isShareLinkUpdateSuccess || isShareLinkUpdateError) {
			closeEditDialog();
		}
	}, [ isShareLinkUpdateSuccess, isShareLinkUpdateError ] );

	useEffect(() => {
		if (shareLinkUpdateError) {
			toast.error(errorString(intl, shareLinkUpdateError));
		}
	}, [ shareLinkUpdateError, intl ]);

	const closeDeleteDialog = () => {
		setDeleteDialogOpened(false);
	};

	const closeEditDialog = () => {
		setEditDialogOpened(false);
	};

	return (
		<ContentWrapper>
			<ShareLinkForm workspaceId={workspaceId} />
			<div style={{ marginTop: "1rem" }}>
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.sharedLinks" })}:
				</Typography>
			</div>
			<Divider />
			<ContentLoader loading={isLoading} error={errorString(intl, error)}>
				{shareLinks?.length ? (
					<>
						<ShareLinkList
							shareLinks={shareLinks}
							setChoosenLinkId={setChosenLinkId}
							setEditDialogOpened={setEditDialogOpened}
							setDeleteDialogOpened={setDeleteDialogOpened}
						/>
						<SimpleDialog
							open={isDeleteDialogOpened}
							onClose={closeDeleteDialog}
							dialogContent={intl.formatMessage({
								id: "app.removeLinkConfirmation"
							})}
							cancelButtonText={intl.formatMessage({
								id: "app.cancel"
							})}
							onConfirmButton={onShareLinkDelete}
							confirmButtonText={intl.formatMessage({
								id: "app.delete"
							})}
							isConfirmInProgress={isShareLinkDeleteInProgress}
						/>
						<SimpleDialog
							open={isEditDialogOpened}
							onClose={closeEditDialog}
							dialogContent={
								<FormControl
									style={{ width: 250 }}
								>
									<Select
										value={newAccessLevel || 1}
										onChange={(e) =>
											setNewAccessLevel(
												e.target.value as AccessLevel
											)
										}
									>
										{shareAccessLevel.map((level) => (
											<MenuItem
												key={level.value}
												value={level.value}
											>
												{intl.formatMessage({
													id: level.translationId
												})}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							}
							cancelButtonText={intl.formatMessage({
								id: "app.cancel"
							})}
							onConfirmButton={editLink}
							confirmButtonText={intl.formatMessage({
								id: "app.save"
							})}
							isConfirmInProgress={isShareLinkUpdateInProgress}
						/>
					</>
				) : (
					<Typography variant="subtitle1">
						{intl.formatMessage({ id: "app.emptySharedLinks" })}
					</Typography>
				)}
			</ContentLoader>
		</ContentWrapper>
	);
};

export default injectIntl(ShareLink);

interface IStatus {
    status: string
}

interface IResponse {
    response: IStatus
}

function hasStatus(obj: unknown): obj is IStatus {
    return (obj as IStatus)?.status !== undefined
        && typeof (obj as IStatus).status === "string";
}

function hasResponse(obj: unknown): obj is IResponse {
    return (obj as IResponse)?.response !== undefined
        && hasStatus((obj as IResponse)?.response);
}

export function extractErrors(error: unknown, statusMap?: Record<string, string>) {
    let status = undefined;
    if (hasStatus(error)) {
        status = String(error.status);
    }

    if (hasResponse(error)) {
        status = String(error.response.status);
    }

    if (statusMap !== undefined && status !== undefined && status in statusMap) {
        return { error: statusMap[status]}
    }

    return { error: "app.somethingWentWrong" }
}
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import { Box } from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ContentPage from "components/common/ContentPage/ContentPage";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";
import {
	useCreateWorkspaceMutation,
	useGetWorkspaceQuery,
	useUpdateWorkspaceMutation
} from "../../../../store/apis/workspace";
import WorkspaceForm from "./WorkspaceForm";

interface WorkspaceFormProps {
	workspaceId?: number;
}

const WorkspaceEditPage: FunctionComponent<WorkspaceFormProps & WrappedComponentProps> = (
	{ workspaceId, intl }) => {
	const navigate = useNavigate();

	const { data: workspace, isLoading, error } = useGetWorkspaceQuery(workspaceId || 0, {
		skip: !workspaceId
	});
	const [ updateWorkspace,
		{
			isSuccess: isWorkspaceUpdateSuccess,
			isLoading: isWorkspaceUpdateInProgress,
			error: updateError
		}
	] = useUpdateWorkspaceMutation();
	const [ createWorkspace,
		{
			isSuccess: isWorkspaceCreateSuccess,
			isLoading: isWorkspaceCreateInProgress,
			error: createError
		}
	] = useCreateWorkspaceMutation();

	useEffect(() => {
		if (isWorkspaceCreateSuccess || isWorkspaceUpdateSuccess) {
			navigate(pages.workspaces.url());
		}
	}, [ isWorkspaceCreateSuccess, isWorkspaceUpdateSuccess, navigate ])


	const onSubmit = async ({ workspaceName }: FieldValues) => {
		const data = {
			name: workspaceName
		};

		if (workspaceId)
			updateWorkspace({ workspaceId, data });
		else {
			createWorkspace(data);
		}
	};

	if (error) {
		return <ContentError error={errorString(intl, error)} />
	}

	return (
		<ContentPage
			title={
				workspaceId
					? intl.formatMessage({ id: "app.editWorkspace" })
					: intl.formatMessage({ id: "app.addWorkspace" })
			}
			closeLink={pages.workspaces.url()}
			loading={isLoading}
		>
			<Box component="div" sx={{ flexGrow: 4, mx: "auto", mt: 2, maxWidth: "700px" }}>
				<WorkspaceForm
					workspace={workspace}
					onSubmit={onSubmit}
					error={updateError || createError}
					closeLink={pages.workspaces.url()}
					inProgress={isWorkspaceCreateInProgress || isWorkspaceUpdateInProgress}
				/>
			</Box>
		</ContentPage>
	);
};

export default injectIntl(WorkspaceEditPage);

import { FunctionComponent, MouseEventHandler, useState } from "react";
import {
	Avatar,
	ListItemText,
	Menu,
	MenuItem,} from "@mui/material";
import { changeLanguage, selectLanguage } from "store/reducers/language/languageReducer";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useAppDispatch, useAppSelector } from "hooks";

const LanguageMenu: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const dispatch = useAppDispatch();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const language = useAppSelector(selectLanguage);

	const handleClick: MouseEventHandler<HTMLElement> = (event) => {
		if (!anchorEl) {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleLanguageClick = (language: string) => {
		dispatch(changeLanguage(language));
		handleCloseMenu();
	};

	return (
		<>
			<Avatar
				// color="inherit"
				variant="square"
				onClick={handleClick}
				aria-label={intl.formatMessage({
					id: "app.language"
				})}
				sx={{ width: 24, height: 24, fontSize: "1rem", bgcolor: "#1c1b22" }}
			>
				{language.short.toUpperCase()}
			</Avatar>

			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}
				elevation={0}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				<MenuItem onClick={() => handleLanguageClick("en-GB")}>
					<ListItemText
						primary={intl.formatMessage({
							id: "app.english"
						})}
					/>
				</MenuItem>
				<MenuItem onClick={() => handleLanguageClick("pl-PL")}>
					<ListItemText
						primary={intl.formatMessage({
							id: "app.polish"
						})}
					/>
				</MenuItem>
				
			</Menu>
		</>
	);
};

export default injectIntl(LanguageMenu);

import { IntlShape } from "react-intl";

export function getNumberParam(
    value: string | undefined,
    name: string,
    intl: IntlShape
): [
    param: number | undefined,
    error: string | undefined
] {
    if (value === undefined) {
        return [undefined, undefined];
    }

    const tagId = parseInt(value);
    const paramError = isNaN(tagId) ?
        intl.formatMessage({
            id: "app.invalidParameterValue"
        },
        {
            name: name,
            value: value
        })
        : undefined;

    return [tagId, paramError];
}
import { FunctionComponent, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Tag } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { errorString } from "helpers/api";
import { getNumberParam } from "helpers/views";
import { useCreateDishMutation, useGetDishQuery, useUpdateDishMutation } from "../../../../store/apis/dish";
import { useGetTagsQuery } from "../../../../store/apis/tag";
import DishForm from "./DishForm";
import { Box } from "@mui/material";


type DishFormParams = {
	dishId: string;
}

type DishFormSubmit = (payload: FieldValues) => void;

const DishPage: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const navigate = useNavigate();

	const params = useParams<DishFormParams>();
	const [ dishId, paramError ] = getNumberParam(params.dishId, "dishId", intl);

	const closeLink = pages.workspace.dishes.url(workspaceId);

	const { data: dish, error, isLoading}  = useGetDishQuery({ workspaceId, dishId: dishId || 0 }, {
		skip: !dishId
	});
	const [ updateDish, { isLoading: isUpdating, error: updateError, isSuccess: updateSuccess }] = useUpdateDishMutation();
	const [ createDish, { isLoading: isCreating, error: createError, isSuccess: createSuccess }] = useCreateDishMutation();

	const { data: tags, error: tagsError, isError: isErrorTags, isLoading: isLoadingTags}  = useGetTagsQuery(workspaceId);

	const onSubmit: DishFormSubmit = async ({
		name,
		recipe = '',
		time_effort,
		kilocalories = 0,
		spring = false,
		summer = false,
		autumn = false,
		winter = false,
		tags = []
	}) => {
		const data = {
			name,
			time_effort,
			recipe,
			spring,
			summer,
			autumn,
			winter,
			kilocalories: Number(kilocalories),
			tags: tags.map((tag: Tag) => tag.id)
		};

		if (dishId) {
			updateDish({ workspaceId, dishId, data });
		} else {
			createDish({ workspaceId, data });
		}
	};

	useEffect(() => {
		if (updateSuccess || createSuccess) {
			navigate(closeLink);
		}
	}, [ updateSuccess, createSuccess, navigate, closeLink ]);

	return (
		<ContentPage
			title={
				dishId
					? intl.formatMessage({ id: "app.editDish" })
					: intl.formatMessage({ id: "app.addDish" })
			}
			closeLink={closeLink}
			loading={isLoading && !!workspaceId}
			error={errorString(intl, paramError || error)}
		>
			<Box component="div" sx={{ flexGrow: 4, mx: "auto", mt: 2, maxWidth: "700px" }}>
				<DishForm
					dish={dish}
					onSubmit={onSubmit}
					tags={tags}
					tagsLoading={isLoadingTags}
					tagsError={tagsError}
					isTagsError={isErrorTags}
					error={updateError || createError}
					closeLink={closeLink}
					processing={isUpdating || isCreating}
				/>
			</Box>
		</ContentPage>
	);
};

export default injectIntl(DishPage);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dish, Dishes, DishFilter, DishOrder, OrderDirection } from "@s6e/spicify-api-sdk-js";
import { Rank, Ranking } from "@s6e/spicify-api-sdk-js";
import { RootState } from "store/store";

interface DishesState {
	filter: DishFilter;
	sorting: DishOrder;
}
const initialState: DishesState = {
	filter: {},
	sorting: [
		{
			field: "name",
			direction: OrderDirection.Ascending
		}
	]
};

export const selectFilter = (state: RootState): DishFilter => state.dishes.filter;
export const selectSorting = (state: RootState): DishOrder => state.dishes.sorting;

export const generatorSlice = createSlice({
	name: "dishes",
	initialState,
	reducers: {
		setFilter: (state, action: PayloadAction<DishFilter>) => {
			state.filter = action.payload;
		},
		setSorting: (state, action: PayloadAction<DishOrder>) => {
			state.sorting = action.payload;
		}
	}
});

export const { setFilter, setSorting } = generatorSlice.actions;

const dishesReducer = generatorSlice.reducer;

export default dishesReducer;

function compareByRanking(ranking: Ranking): (dish: Dish, other: Dish) => number {
	const rankingIds = ranking.map((r: Rank) => r.id);
	return (dish: Dish, other: Dish) => rankingIds.indexOf(dish.id) - rankingIds.indexOf(other.id);
}

function filterByRanking(ranking: Ranking): (dish: Dish) => boolean {
	const rankingIds = ranking.map((r: Rank) => r.id);
	return (dish: Dish) => rankingIds.indexOf(dish.id) > -1;
}

export const sortDishes = (dishes: Dishes, ranking: Ranking): Dishes => {
	if (dishes.length === 0) {
		return [];
	}

	let result = dishes.map((d: Dish) => d);

	result = result.filter(filterByRanking(ranking))
	result.sort(compareByRanking(ranking));

	return result;
}

import { FunctionComponent, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import Logo from "components/common/Logo/Logo";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";

import { useConfirmEmailMutation } from "../../../store/apis/auth";
import ContentLoader from "components/common/ContentPage/ContentLoader";
import { Stack } from "@mui/material";


type ConfirmParams = {
	code?: string;
};

const Confirm: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	
	const { code } = useParams<ConfirmParams>();

	const navigate = useNavigate();

	const [ confirmEmail, { isUninitialized, isSuccess, isLoading, error } ] = useConfirmEmailMutation();

	useEffect(() => {
		if (code && isUninitialized) {
			confirmEmail({ code });
		}
	}, [code, isUninitialized, confirmEmail]);

	useEffect(() => {
		if (isSuccess) {
			toast.success(
				`🙌 ${intl.formatMessage({ id: "app.successConfirm" })}`
			);
			navigate(pages.login.url());
		}
	})

	const errorMessage = !code
		? intl.formatMessage({ id: "app.missingConfirmationCode" })
		: errorString(intl, error);
	return (
		<Stack maxWidth={300} direction="column"  sx={{
			mx: "auto",
			mt: "3rem",
		}}>
			<Logo />
			<ContentLoader loading={isLoading}>
				<ContentError error={errorMessage} />
			</ContentLoader>			
		</Stack>
	);
};

export default injectIntl(Confirm);

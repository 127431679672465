import { useAppSelector } from "hooks";
import { pages } from "pages";
import { FunctionComponent, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import ContentLoader from "../ContentPage/ContentLoader";

type ProtectedRouteProps = {
	reverse?: boolean;
	children: ReactNode;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
	reverse = false,
	children
}) => {
	const isAuthenticated = useAppSelector(
		(state) => state.auth.isAuthenticated
	);

	return <ContentLoader loading={isAuthenticated === null}>
		{reverse && isAuthenticated ?
			<Navigate to={pages.main.url()} replace />
		: !reverse && !isAuthenticated ? 
			<Navigate to={pages.login.url()} replace />
		: children
		}
	</ContentLoader>
  };

export default ProtectedRoute;

import { ComponentProps, FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import {
	TextField,
	Button,
	Chip,
	Typography,
	Stack,
	FormLabel
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import SubmitButton from "components/common/SubmitButton";
import { Tag } from "@s6e/spicify-api-sdk-js";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";


export type TagFormSubmit = (payload: FieldValues) => void;

type TagFormProps = ComponentProps<"form"> & {
	onSubmit: TagFormSubmit,
	inProgress: boolean,
	closeLink: string,
	error: unknown,
	tag?: Tag,
} & WrappedComponentProps

const TagForm: FunctionComponent<TagFormProps> = ({ 
	onSubmit,
	tag,
	closeLink,
	error,
	inProgress,
	intl
}) => {
	const [chipPreview, setChipPreview] = useState({
		name: tag?.name || "preview",
		font_color: tag?.font_color || "#ffffff",
		background_color: tag?.background_color || "#000000",
	});

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors }
	} = useForm<Tag>({
		defaultValues: {
			name: tag?.name || "",
			group: "",
			font_color: tag?.font_color || "#ffffff",
			background_color: tag?.background_color || "#000000"
		}
	});
	
	function updateChipPreview(): void {
		const values = getValues();
		setChipPreview({
			name: values.name,
			font_color: values.font_color,
			background_color: values.background_color
		})
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={2}>
				<TextField
					fullWidth
					{...register("name", {
						required: true,
						onChange: updateChipPreview
					})}
					label={intl.formatMessage({ id: "app.name" })}
					helperText={errors.name?.message?.toString() || ''}
					error={!!errors.name}
				/>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.group" })}
					{...register("group")}

					helperText={errors.group?.message?.toString() || ''}
					error={!!errors.group}
				/>
				<div>
					<FormLabel>
						{intl.formatMessage({ id: "app.font_color" })}
					</FormLabel>
					<input
						type="color"
						{...register("font_color", {
							required: true,
							onChange: updateChipPreview
						})}
						style={{ width: "100%" }} />
				</div>
				<div>
					<FormLabel>
						{intl.formatMessage({ id: "app.background_color" })}
					</FormLabel>
					<input
						type="color"
						{...register("background_color", {
							required: true,
							onChange: updateChipPreview
						})}
						style={{ width: "100%" }} />
				</div>
				<div>
					<div>
						<FormLabel>
							{intl.formatMessage({ id: "app.preview" })}
						</FormLabel>
					</div>
					<Chip
						label={
							<Typography
								variant="caption"
								style={{
									whiteSpace: "normal"
								}}
							>
								{chipPreview.name}
							</Typography>
						}
						color="secondary"
						sx={{
							color: chipPreview.font_color,
							backgroundColor: chipPreview.background_color,
							mt: 0.5
						}}
					/>
				</div>
				<ContentError error={errorString(intl, error)} />
				<Stack direction="row" spacing={2} justifyContent="flex-end">
					<Button
						color="secondary"
						component={Link}
						to={closeLink}
						disabled={inProgress}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton isLoading={inProgress}>
						{intl.formatMessage({ id: "app.save" })}
					</SubmitButton>
				</Stack>
			</Stack>
		</form>
	);
};

export default injectIntl(TagForm);


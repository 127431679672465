import { FunctionComponent } from "react";
import { Dish } from "@s6e/spicify-api-sdk-js";
import {
	Box,
	Chip,
	Stack,
	Typography
} from "@mui/material";
import { Tag } from "@s6e/spicify-api-sdk-js";
import DishTimeIcon from "./DishIcon/DishIcon";
import DishButtons from "./DishButtons";

type DishCardProps = {
	dish: Dish;
	onNameClick?: () => void;
	onFavoriteClick?: () => void;
	onUseClick?: () => void;
	onDropClick?: () => void;
	onEditClick?: () => void;
	onDeleteClick?: () => void;
};

const DishCard: FunctionComponent<DishCardProps> = ({
	dish,
	onNameClick = undefined,
	onFavoriteClick = undefined,
	onUseClick = undefined,
	onDropClick = undefined,
	onEditClick = undefined,
	onDeleteClick = undefined
}) => {

	
	return (
		<Stack flexGrow={4} direction="row" spacing={1}>
			<Box component="div" sx={{ display: "flex", p: 1,  justifyContent: "center", alignContent: "center",  justifyItems: "center", alignItems: "center" }}>
				<DishTimeIcon timeEffort={dish.time_effort} />
			</Box>
			<Stack flexGrow={4} direction="column" spacing={0}>
				<Typography variant="h5">
					<span onClick={onNameClick}>{dish.name}</span>
				</Typography>
				<Stack direction="row" spacing={1}>
					{dish.tags?.map((tag: Tag) => (
						<Chip
							key={tag.name}
							label={
								<Typography
									variant="caption"
									sx={{
										whiteSpace:
											"normal"
									}}
								>
									{tag.name}
								</Typography>
							}
							size="small"
							sx={{
								color: tag.font_color,
								backgroundColor: tag.background_color,
							}}
						/>
					))}
				</Stack>
			</Stack>
			<Box component="div" sx={{ flex: "none",  justifyContent: "center", alignContent: "center",  justifyItems: "center", alignItems: "center" }} >
				<DishButtons
					dish={dish} 
					onFavoriteClick={onFavoriteClick}
					onUseClick={onUseClick}
					onDropClick={onDropClick}
					onEditClick={onEditClick}
					onDeleteClick={onDeleteClick}
				/>
			</Box>
		</Stack>
	);
};

export default DishCard;

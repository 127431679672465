import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Logo from "components/common/Logo/Logo";
import TextWithLink from "components/common/TextWithLink/TextWithLink";
import LoadingButton from "components/common/LoadingButton/LoadingButton";
import { Credentials } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";
import { useLoginMutation } from "../../../store/apis/auth";
import { Stack, TextField } from "@mui/material";


const Login: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const navigate = useNavigate();

	const [ login, { isSuccess, isLoading, error } ] = useLoginMutation()

	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm();

	const submitForm = async (payload: FieldValues) => {
		await login(payload as Credentials);
	};

	useEffect(() => {
		if (isSuccess) {
			navigate(pages.main.url());
		}
	}, [ isSuccess, navigate ])

	return (
		<Stack maxWidth={300} sx={{ mx: "auto" }} justifyContent="center" alignContent="center">
			<Logo />
			<form onSubmit={handleSubmit(submitForm)}>
				<Stack spacing={2}>
					<TextField
						{...register("username", {
							required: intl.formatMessage({ id: "app.usernameIsRequired" })
						})}
						label={intl.formatMessage({ id: "app.username" })}
						helperText={errors.username?.message?.toString() || ''}
						error={!!errors.username}
						fullWidth
					/>
					<TextField
						{...register("password", {
							required: intl.formatMessage({ id: "app.passwordIsRequired" })
						})}
						label={intl.formatMessage({ id: "app.password" })}
						type="password"
						helperText={errors.password?.message?.toString() || ''}
						error={!!errors.password}
						fullWidth
					/>
					<ContentError error={errorString(intl, error)} />
					<LoadingButton
						isLoading={isLoading}
						type="submit"
						color="primary"
						variant="contained"
					>
						{intl.formatMessage({ id: "app.signIn" })}
					</LoadingButton>
				</Stack>
			</form>
			<TextWithLink
				text={intl.formatMessage({ id: "app.dontHaveAccount" })}
				linkHref={pages.register.url()}
				buttonText={intl.formatMessage({ id: "app.signUp" })}
			/>
			<TextWithLink
				text={intl.formatMessage({ id: "app.forgotPassword" })}
				linkHref={pages.resetPassword.url()}
				buttonText={intl.formatMessage({ id: "app.reset" })}
			/>
		</Stack>
	);
};

export default injectIntl(Login);

import { FunctionComponent, ReactNode } from "react";
import { CircularProgress, Stack } from "@mui/material";
import ContentError from "./ContentError";

type ContentLoaderProps = {
	loading?: boolean;
	error?: string;
	children?: ReactNode;
};

const ContentLoader: FunctionComponent<ContentLoaderProps> = ({
	loading,
	error = undefined,
	children
}) => {

	if (error) {
		return (
			<Stack flexGrow={4}>
				<ContentError error={error} />
			</Stack>
		);
	}
	
	return (
		<>
			{!loading ? (
				children
			) : (
				<Stack sx={{ mx: "auto" }} justifyContent="center" alignContent="center">
					<CircularProgress sx={{ m: 1 }} />
				</Stack>
			)}
		</>
	);
};

export default ContentLoader;

import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentPage from "components/common/ContentPage/ContentPage";
import { Dish } from "@s6e/spicify-api-sdk-js";
import {
    sortDishes
} from "store/reducers/dishes/dishesReducer";
import DishList from "components/common/DishList/DishList";
import { pages } from "pages";
import { injectIntl, WrappedComponentProps } from "react-intl";
import {
    useDeleteDishMutation,
    useGetDishesQuery, useSetDishFavoriteMutation, useSetDropDishMutation,
    useSetUseDishMutation, useUnsetDishFavoriteMutation
} from "../../../../../store/apis/dish";
import { useGetRankingQuery } from "store/apis/generator";
import { errorString } from "helpers/api";
import { useAppSelector } from "hooks";
import { selectQuery } from "store/reducers/generator/generatorReducer";
import { Fab } from "@mui/material";
import { Add } from "@mui/icons-material";

type GeneratorPageProps = {
	workspaceId: number;
};

const GeneratorPage: FunctionComponent<GeneratorPageProps & WrappedComponentProps> = ({ intl, workspaceId }) => {
	const navigate = useNavigate();

    const query = useAppSelector(selectQuery);

    const { data: dishes, isLoading: isLoadingDishes, error: dishesError }  = useGetDishesQuery({ workspaceId });
    const { data: ranking, isLoading: isLoadingRanking, error: rankingError }  = useGetRankingQuery({
        workspaceId,
        query
    });

    const [ deleteDish] = useDeleteDishMutation();
    const [ setUseDish, { isSuccess: dishUsed, originalArgs: useDishParams }] = useSetUseDishMutation();
    const [ setDropDish] = useSetDropDishMutation();
    const [ setDishFavorite] = useSetDishFavoriteMutation();
    const [ unsetDishFavorite] = useUnsetDishFavoriteMutation();

    const onDeleteDishClick = async (dish: Dish) => {
        deleteDish({
            workspaceId,
            dishId: dish.id
        });
    }

    const onUseDishClick = async (dish: Dish) => {
        setUseDish({ workspaceId, dishId: dish.id });
    }
    useEffect(() => {
        if (dishUsed && useDishParams) {
            navigate(pages.workspace.dishView.url(workspaceId, useDishParams.dishId));
        }
    }, [ dishUsed, useDishParams, navigate, workspaceId ]);

    const onDropDishClick = async (dish: Dish) => {
        setDropDish({ workspaceId, dishId: dish.id })
    };

    const onEditDishClick = async (dish: Dish) => {
        navigate(pages.workspace.dishEdit.url(workspaceId, dish.id));
    }

    const onToggleFavoriteClick = async (dish: Dish) => {
        if (dish.favorite) {
			unsetDishFavorite({ workspaceId, dishId: dish.id });
		} else {
			setDishFavorite({ workspaceId, dishId: dish.id });
		}
    }
    
    const viewDish = async (dish: Dish) => {
        navigate(pages.workspace.dishView.url(workspaceId, dish.id));
    }

    const onAddDishClick = () => {
        navigate(pages.workspace.dishCreate.url(workspaceId));
    };

	return (
        <ContentPage
            loading={isLoadingDishes || isLoadingRanking}
            title={intl.formatMessage({ id: "app.suggested" })}
            settingsLink="settings"
            error={errorString(intl, dishesError || rankingError)}
            button={{ text: intl.formatMessage({ id: "app.addDish" }), href: pages.workspace.dishCreate.url(workspaceId)}}
        >
            {!!dishes && !!ranking && (
                <DishList
                    dishes={sortDishes(dishes, ranking)}
                    onDishDeleteClick={onDeleteDishClick}
                    onDishUseClick={onUseDishClick}
                    onDishDropClick={onDropDishClick}
                    onDishEditClick={onEditDishClick}
                    onDishFavoriteClick={onToggleFavoriteClick}
                    onDishNameClick={viewDish}
                />)}
            <Fab color="primary" aria-label="add" sx={{ position: "fixed", bottom: "70px", right: "16px", display: { sm: "inline-flex", md: "none" }}}>
                <Add onClick={onAddDishClick} />
            </Fab>
        </ContentPage>
	);
};

export default injectIntl(GeneratorPage);

import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { DishFilter } from "@s6e/spicify-api-sdk-js";
import SubmitButton from "components/common/SubmitButton";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";

type DishFilterFormSubmit = (payload: FieldValues) => void;
export type DishFilterFormProps = {
	filter: DishFilter,
	onSubmit: DishFilterFormSubmit,
	error?: unknown,
	closeLink: string,
	processing?: boolean
}

const DishFilterForm: FunctionComponent<DishFilterFormProps & WrappedComponentProps> = ({
	filter,
	onSubmit,
	error,
	closeLink,
	processing,
	intl
}) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({ 
		shouldUnregister: true,
		defaultValues: {
			name: filter.name,
			recipe: filter.recipe,
			time_effort: filter.time_effort ?? '',
			spring: filter.spring !== undefined ? Number(filter.spring) : '',
			summer: filter.summer !== undefined ? Number(filter.summer) : '',
			autumn: filter.autumn !== undefined ? Number(filter.autumn) : '',
			winter: filter.winter !== undefined ? Number(filter.winter) : '',
		}
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={2}>
				<TextField
					{...register("name")}
					label={intl.formatMessage({ id: "app.name" })}
					helperText={errors.name?.message?.toString() || ''}
					error={!!errors.name}
					fullWidth
				/>
				<TextField
					{...register("recipe")}
					label={intl.formatMessage({ id: "app.recipe" })}
					helperText={errors.name?.message?.toString() || ''}
					error={!!errors.name}
					fullWidth
				/>
				<FormControl
					error={!!errors.time_effort}
					fullWidth
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.timeEffort" })}
					</InputLabel>
					<Controller
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.timeEffort"
								})}
								sx={{ textAlign: "left" }}
								{...field}
							>
								<MenuItem value="">
									{intl.formatMessage({
										id: "app.choose"
									})}
								</MenuItem>
								<MenuItem value={0}>
									{intl.formatMessage({
										id: "app.short"
									})}
								</MenuItem>
								<MenuItem value={1}>
									{intl.formatMessage({
										id: "app.medium"
									})}
								</MenuItem>
								<MenuItem value={2}>
									{intl.formatMessage({ id: "app.long" })}
								</MenuItem>
							</Select>
						)}
						name="time_effort"
						control={control}
					/>
				</FormControl>
				<FormControl
					fullWidth
					error={!!errors.spring}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.spring" })}
					</InputLabel>
					<Controller
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.spring"
								})}
								sx={{ textAlign: "left" }}
								{...field}
							>
								<MenuItem value="">
									{intl.formatMessage({
										id: "app.choose"
									})}
								</MenuItem>
								<MenuItem value={0}>
									{intl.formatMessage({
										id: "app.no"
									})}
								</MenuItem>
								<MenuItem value={1}>
									{intl.formatMessage({
										id: "app.yes"
									})}
								</MenuItem>
							</Select>
						)}
						name="spring"
						control={control}
					/>
				</FormControl>
				<FormControl
					fullWidth
					error={!!errors.summer}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.summer" })}
					</InputLabel>
					<Controller
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.summer"
								})}
								sx={{ textAlign: "left" }}
								{...field}
							>
								<MenuItem value="">
									{intl.formatMessage({
										id: "app.choose"
									})}
								</MenuItem>
								<MenuItem value={0}>
									{intl.formatMessage({
										id: "app.no"
									})}
								</MenuItem>
								<MenuItem value={1}>
									{intl.formatMessage({
										id: "app.yes"
									})}
								</MenuItem>
							</Select>
						)}
						name="summer"
						control={control}
					/>
				</FormControl>
				<FormControl
					fullWidth
					error={!!errors.autumn}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.autumn" })}
					</InputLabel>
					<Controller
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.autumn"
								})}
								sx={{ textAlign: "left" }}
								{...field}
							>
								<MenuItem value="">
									{intl.formatMessage({
										id: "app.choose"
									})}
								</MenuItem>
								<MenuItem value={0}>
									{intl.formatMessage({
										id: "app.no"
									})}
								</MenuItem>
								<MenuItem value={1}>
									{intl.formatMessage({
										id: "app.yes"
									})}
								</MenuItem>
							</Select>
						)}
						name="autumn"
						control={control}
					/>
				</FormControl>
				<FormControl
					fullWidth
					error={!!errors.winter}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.winter" })}
					</InputLabel>
					<Controller
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.winter"
								})}
								sx={{ textAlign: "left" }}
								{...field}
							>
								<MenuItem value="">
									{intl.formatMessage({
										id: "app.choose"
									})}
								</MenuItem>
								<MenuItem value={0}>
									{intl.formatMessage({
										id: "app.no"
									})}
								</MenuItem>
								<MenuItem value={1}>
									{intl.formatMessage({
										id: "app.yes"
									})}
								</MenuItem>
							</Select>
						)}
						name="winter"
						control={control}
					/>
				</FormControl>

				<ContentError error={errorString(intl, error)} />		

				<Stack direction="row" spacing={2} justifyContent="flex-end">
					<Button
						color="secondary"
						component={Link}
						to={closeLink}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton isLoading={processing}>
							{intl.formatMessage({ id: "app.save" })}
					</SubmitButton>
				</Stack>
			</Stack>
		</form>
	);
};

export default injectIntl(DishFilterForm);

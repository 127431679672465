import { FunctionComponent } from "react";
import LinkIcon from "@mui/icons-material/Link";

type RecipeProps = {
	recipe: string;
};

const Recipe: FunctionComponent<RecipeProps> = ({ recipe }) => {
	if (
		recipe.startsWith("https://") ||
		recipe.startsWith("http://")
	) {
		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<LinkIcon />
				<a
					href={recipe}
					target="_blank"
					rel="noreferrer noopener"
					style={{ marginLeft: "1rem" }}
				>
					{recipe}
				</a>
			</div>
		);
	} else {
		return <div>{recipe}</div>;
	}
};

export default Recipe;

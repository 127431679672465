import { FunctionComponent, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { injectIntl, WrappedComponentProps } from "react-intl";
import api from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";

const UserMenu: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);
	const theme = useTheme();
	const navigate = useNavigate();
	const isMobileNavDisplayed = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClick: MouseEventHandler<HTMLElement> = (event) => {
		if (isMobileNavDisplayed) {
			navigate(pages.profile.url());
		} else if (!anchorEl) {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleProfileClick = () => {
		navigate(pages.profile.url());
		handleCloseMenu();
	};

	const handleSignOut = () => {
		if (isProfileDialogOpen) {
			setProfileDialogOpen(false);
		}
		api.logout();
	};


	return (
		<>
			<div style={{
				marginLeft: "auto"
			}}>
				<IconButton
					color="inherit"
					onClick={handleClick}
					aria-label="profile"
				>
					<AccountCircleIcon />
				</IconButton>

				{!isMobileNavDisplayed && (
					<Menu
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleCloseMenu}
						elevation={0}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center"
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center"
						}}
					>
						<MenuItem onClick={handleProfileClick}>
							<ListItemIcon>
								<PersonIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({
									id: "app.profile"
								})}
							/>
						</MenuItem>
						<MenuItem onClick={handleSignOut}>
							<ListItemIcon>
								<PowerSettingsNewIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary={intl.formatMessage({
									id: "app.logout"
								})}
							/>
						</MenuItem>
					</Menu>
				)}
			</div>
		</>
	);
};

export default injectIntl(UserMenu);

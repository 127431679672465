import { ComponentProps, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { TextField, Button, Stack } from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ContentError from "components/common/ContentPage/ContentError";
import SubmitButton from "components/common/SubmitButton";
import { errorString } from "helpers/api";
import { Workspace } from "@s6e/spicify-api-sdk-js";

export type WorkspaceFormSubmit = (payload: FieldValues) => void;

type WorkspaceFormProps = ComponentProps<"form"> & {
	onSubmit: WorkspaceFormSubmit,
	inProgress: boolean,
	closeLink: string,
	error: unknown,
	workspace?: Workspace,
} & WrappedComponentProps

const WorkspaceForm: FunctionComponent<WorkspaceFormProps & WrappedComponentProps> = ({ 
	onSubmit,
	workspace,
	closeLink,
	error,
	inProgress,
	intl
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues: {
			workspaceName: workspace?.name || ""
		}
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={2}>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.name" })}
					{...register("workspaceName", { required: true })}
					name="workspaceName"

					helperText={errors.workspaceName?.message?.toString() || ''}
					error={!!errors.workspaceName}
				/>
				<ContentError error={errorString(intl, error)} />
				<Stack direction="row" spacing={2} justifyContent="flex-end">
					<Button
						color="secondary"
						component={Link}
						to={closeLink}
						disabled={inProgress}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton isLoading={inProgress}>
						{intl.formatMessage({ id: "app.save" })}
					</SubmitButton>
				</Stack>
			</Stack>
		</form>
	);
};

export default injectIntl(WorkspaceForm);

import { FunctionComponent } from "react";
import {
	Radio,
	RadioGroup,
	FormControlLabel,
	RadioGroupProps
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { generatorRadioTagsOptions } from "consts";

type TagValuePickerProps = { groupKey?: string } & RadioGroupProps;

const TagValuePicker: FunctionComponent<TagValuePickerProps & WrappedComponentProps> = ({
	defaultValue = undefined,
	name = undefined,
	groupKey = undefined,
	onChange = undefined,
	value = undefined,
	intl
}) => {
	return (
		<RadioGroup
			style={{ flexDirection: "row" }}
			value={value}
			onChange={onChange}
			name={name}
			key={groupKey}
			defaultValue={defaultValue}
		>
			{generatorRadioTagsOptions.map((option) => (
				<FormControlLabel
					key={option.value}
					value={String(option.value)}
					control={<Radio name={name} />}
					label={intl.formatMessage({
						id: option.translationId
					})}
				/>
			))}
		</RadioGroup>
	);
};

export default injectIntl(TagValuePicker);

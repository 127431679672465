import { ComponentProps, FunctionComponent } from "react";
import LoadingButton from "./LoadingButton/LoadingButton";

type SubmitButtonProps = ComponentProps<
	typeof LoadingButton
>

const SubmitButton: FunctionComponent<SubmitButtonProps> = (buttonProps) => {
	return <LoadingButton color="primary" variant="contained" type="submit" {...buttonProps} />
};

export default SubmitButton;

import { FunctionComponent } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DishesPage from "./Dishes/DishesPage";
import { pages } from "pages";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import GeneratorPage from "./Dishes/GeneratorPage";

const WorkspacePage: FunctionComponent<WorkspaceContextComponentProps> = ({ workspaceId }) => {
	return (
		<Routes>
			<Route path={pages.workspace.suggested.pattern} element={
				<GeneratorPage workspaceId={workspaceId} />
			} />
			<Route path={pages.workspace.dishes.pattern} element={
				<DishesPage workspaceId={workspaceId} />
			} />
			<Route path="*" element={
				<Navigate to={pages.workspace.suggested.url(workspaceId)} />
			} />
		</Routes>
	)
};

export default WorkspacePage;

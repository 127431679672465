import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import {
	Box,
} from "@mui/material";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { DishOrder } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { selectSorting, setSorting } from "store/reducers/dishes/dishesReducer";
import DishSortingForm from "./DishSortingForm";

const DishSortingView: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const closeLink = pages.workspace.dishes.url(workspaceId);
	const sorting = useAppSelector(selectSorting);
	const onSubmit = async (formData: FieldValues) => {
		const dataToSend: DishOrder = formData.rows.map(({field, direction}: {field: string, direction: number}) => ({
			field: field,
			direction: direction
		}));

		console.log(formData);
		dispatch(setSorting(dataToSend));
		navigate(closeLink);
	};

	const breadcrumbs = [
		{
			label: intl.formatMessage({ id: "app.dishes" }),
			url: pages.workspace.dishes.url(workspaceId),
		}
	]

	return (
		<ContentPage
			breadcrumbs={breadcrumbs}
			title={intl.formatMessage({ id: "app.dish_order" })}
			closeLink={closeLink}
		>
			<Box component="div" sx={{ flexGrow: 4, mx: "auto", mt: 2, maxWidth: "700px" }}>
				<DishSortingForm
					onSubmit={onSubmit}
					closeLink={closeLink}
					sorting={sorting}
				/>
			</Box>
		</ContentPage>
	);
};

export default injectIntl(DishSortingView);

import { pages } from "pages";
import { FunctionComponent, useEffect } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Navigate } from "react-router";
import { toast } from "react-toastify";
import { useGetWorkspacesQuery } from "../../../store/apis/workspace";
import { errorString } from "../../../helpers/api";
import ContentLoader from "components/common/ContentPage/ContentLoader";

const MainPage: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const { data: workspaces, isLoading, error } = useGetWorkspacesQuery()
	
	useEffect(() => {
		if (error) {
			toast.error(errorString(intl, error));
		}
	}, [ error, intl ]);


	const personalWorkspace = workspaces?.find(w => w.personal);
	return <ContentLoader
		loading={isLoading}
	>
		<>
			{!!personalWorkspace && <Navigate to={pages.workspace.suggested.url(personalWorkspace.id)} />}
		</>
	</ContentLoader>;
}

export default injectIntl(MainPage);
import {
	configureStore,
	combineReducers,
	Reducer,
	Action
} from "@reduxjs/toolkit";
import languageReducer from "./reducers/language/languageReducer";
import generatorReducer from "./reducers/generator/generatorReducer";
import layoutReducer from "./reducers/layout/layoutReducer";
import { dishesApi } from "./apis/dish";
import { authApi } from "./apis/auth";
import authReducer, { signOut } from "./reducers/auth/authReducer";
import { shareLinkApi } from "./apis/shareLink";
import { shareApi } from "./apis/share";
import { tagApi } from "./apis/tag";
import { generatorApi } from "./apis/generator";
import { invitationApi } from "./apis/invitation";
import { workspaceApi } from "./apis/workspace";
import dishesReducer from "./reducers/dishes/dishesReducer";

export type RootState = ReturnType<typeof store.getState>;

const shouldEnableDevTools =
	process.env.NODE_ENV !== "production" &&
	typeof window !== "undefined" &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const rootReducer = combineReducers({
	auth: authReducer,
	layout: layoutReducer,
	language: languageReducer,
	generator: generatorReducer,
	dishes: dishesReducer,
	[authApi.reducerPath]: authApi.reducer,
	[dishesApi.reducerPath]: dishesApi.reducer,
	[workspaceApi.reducerPath]: workspaceApi.reducer,
	[tagApi.reducerPath]: tagApi.reducer,
	[shareLinkApi.reducerPath]: shareLinkApi.reducer,
	[shareApi.reducerPath]: shareApi.reducer,
	[invitationApi.reducerPath]: invitationApi.reducer,
	[generatorApi.reducerPath]: generatorApi.reducer,
});

const appReducer: Reducer = (state: RootState, action: Action) => {
	if (signOut.match(action)) {
		state = undefined;
	}

	return rootReducer(state, action);
};

const store = configureStore({
	reducer: appReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(authApi.middleware)
			.concat(dishesApi.middleware)
			.concat(workspaceApi.middleware)
			.concat(tagApi.middleware)
			.concat(shareLinkApi.middleware)
			.concat(shareApi.middleware)
			.concat(invitationApi.middleware)
			.concat(generatorApi.middleware)
	,
	devTools: !!shouldEnableDevTools
});

export type AppDispatch = typeof store.dispatch;

export default store;

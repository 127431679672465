import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "hooks";

import App from "./App";

import theme from "theme/theme";

import "react-toastify/dist/ReactToastify.css";
import { selectLanguage } from "store/reducers/language/languageReducer";

const Bootstrap = () => {
	const language = useAppSelector(selectLanguage);

	return (
		<IntlProvider locale={language.intlLocale} messages={language.language}>
			<ThemeProvider theme={theme}>
				<App />
				<ToastContainer />
			</ThemeProvider>
		</IntlProvider>
	);
};

export default Bootstrap;

export const emailPattern =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const workspaceFilterLimit = 5;
export const generatorRadioTagsOptions = [
	{ translationId: "app.exclude", value: -2 },
	{ translationId: "app.prefferNot", value: -1 },
	{ translationId: "app.ignore", value: 0 },
	{ translationId: "app.preffer", value: 1 },
	{ translationId: "app.only", value: 2 }
];
export const languageStorageKey = "Choosen_Locale";
export const shareAccessLevel = [
	{ translationId: "app.view", value: 1 },
	{ translationId: "app.edit", value: 2 },
	{ translationId: "app.share", value: 3 },
	{ translationId: "app.admin", value: 4 },
	{ translationId: "app.owner", value: 5 }
];

import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Button,
	FormLabel,
	TextField,
	Stack
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { tagsToFormControls } from "helpers/tags";
import { GenerateQuery, Tags } from "@s6e/spicify-api-sdk-js";
import TagValuePicker from "./TagValuePicker";
import { ImpactValue, TimeEffort } from "@s6e/spicify-api-sdk-js";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";
import SubmitButton from "components/common/SubmitButton";


type GeneratorFilterFormSubmit = (payload: FieldValues) => void;
export type GeneratorFilterFormProps = {
	query: GenerateQuery,
	onSubmit: GeneratorFilterFormSubmit,
	tags: Tags,
	error?: unknown,
	closeLink: string,
	processing?: boolean
}

const GeneratorFilterForm: FunctionComponent<GeneratorFilterFormProps & WrappedComponentProps> = ({
	query,
	onSubmit,
	tags,
	error,
	closeLink,
	processing,
	intl
}) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({ 
		shouldUnregister: true,
		defaultValues: query
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={2}>
				<FormControl
					fullWidth
					error={!!errors.time_effort}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.time_effort" })}
					</InputLabel>
					<Controller
						name="time_effort"
						control={control}
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.time_effort"
								})}
								style={{ textAlign: "left" }}								
								{...field}
							>
								<MenuItem value={TimeEffort.Short}>
									{intl.formatMessage({
										id: "app.short"
									})}
								</MenuItem>
								<MenuItem value={TimeEffort.Medium}>
									{intl.formatMessage({
										id: "app.medium"
									})}
								</MenuItem>
								<MenuItem value={TimeEffort.Long}>
									{intl.formatMessage({ id: "app.long" })}
								</MenuItem>
							</Select>
						)}
						
						rules={{ required: true }}
						defaultValue={TimeEffort.Long}
					/>
					<FormHelperText>
						{errors.time_effort &&
							intl.formatMessage({ id: "app.emptyField" })}
					</FormHelperText>
				</FormControl>

				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.kilocalories" })}
					{...register("kilocalories")}
					type="number"
					slotProps={{
						htmlInput: { min: "0", max: "10000", step: "1" }
					}}
				/>

				<FormControl
					fullWidth
					component="fieldset"
					error={!!errors.season}
				>
					<FormLabel component="legend">
						{intl.formatMessage({ id: "app.season" })}
					</FormLabel>
					<Controller
						rules={{ required: true }}
						control={control}
						name="season"
						defaultValue={ImpactValue.Ignore}
						render={({ field }) => (
							<TagValuePicker {...field} />
						)}
					/>
					<FormHelperText>
						{errors.season &&
							intl.formatMessage({
								id: "app.radioRequired"
							})}
					</FormHelperText>
				</FormControl>

				{tags &&
					tagsToFormControls(tags).map((group) => (
						<FormControl
							fullWidth
							aria-label="position"
							key={group.groupName}
						>
							<FormLabel sx={{ textAlign: "center" }}>
								{group.groupName}
							</FormLabel>
							{group.values.map((indexedTag) => {							
								return (
									<FormControl
										component="fieldset"
										key={indexedTag.id}
									>
										<input 
											type="hidden"
											{ ...register(`tags.${indexedTag.index}.id`)}
											value={indexedTag.id}
												/>
										<FormLabel component="legend">
											{indexedTag.name}
										</FormLabel>
										<Controller
											rules={{ required: true }}
											control={control}
											name={`tags.${indexedTag.index}.value`}
											defaultValue={ImpactValue.Ignore}
											render={({ field }) => (
												<TagValuePicker {...field} />
											)}
										/>
										<FormHelperText>
											{/* {errors.tags?[index] &&
												intl.formatMessage({
													id: "app.radioRequired"
												})} */}
										</FormHelperText>
									</FormControl>
								);
							})}
						</FormControl>
					))}

				<ContentError error={errorString(intl, error)} />

				<Stack direction="row" spacing={2} justifyContent="flex-end">
					<Button
						color="secondary"
						component={Link}
						to={closeLink}
						disabled={processing}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton isLoading={processing}>
						{intl.formatMessage({ id: "app.save" })}
					</SubmitButton>
				</Stack>
			</Stack>
		</form>
	);
};

export default injectIntl(GeneratorFilterForm);

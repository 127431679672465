import { IntlShape } from "react-intl";

export const errorString = (intl: IntlShape, error: unknown): string | undefined => {
    if (error === undefined || error === null) {
        return undefined;
    }

    if (typeof error === "string") {
        return intl.formatMessage({ id: error });
    }

    return intl.formatMessage({ id: "app.somethingWentWrong" });
}
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import Logo from "components/common/Logo/Logo";
import LoadingButton from "components/common/LoadingButton/LoadingButton";
import TextWithLink from "components/common/TextWithLink/TextWithLink";
import { ResetPasswordRequest } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import { errorString } from "helpers/api";
import ContentError from "components/common/ContentPage/ContentError";
import { useInitializeResetPasswordFlowMutation } from "../../../store/apis/auth";
import { Stack, TextField } from "@mui/material";


const PasswordResetStep1: FunctionComponent<WrappedComponentProps> = ({
	intl
}) => {
	
	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm();

	const [ initializeResetPasswordFlow, { error, isSuccess, isLoading } ] = useInitializeResetPasswordFlowMutation();
	
	const navigate = useNavigate();

	const submitForm = async (payload: FieldValues) => {
		initializeResetPasswordFlow(payload as ResetPasswordRequest);
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success(
				`🙌 ${intl.formatMessage({
					id: "app.successResetPasswordRequest"
				})}`
			);
			navigate(pages.login.url());
		}
	}, [isSuccess, intl, navigate]);

	return (
		<Stack maxWidth={300} sx={{ mx: "auto" }} justifyContent="center" alignContent="center">
			<Logo />
			<form onSubmit={handleSubmit(submitForm)}>
				<Stack spacing={2}>
					<TextField
						label={intl.formatMessage({ id: "app.email" })}
						{...register("email", {
							required: intl.formatMessage({ id: "app.emailIsRequired" })
						})}
						helperText={errors.email?.message?.toString() || ''}
						error={!!errors.email}
						fullWidth
					/>
					<ContentError error={errorString(intl, error)} />
					<LoadingButton
						isLoading={isLoading}
						type="submit"
						color="primary"
						variant="contained"
					>
						{intl.formatMessage({ id: "app.reset" })}
					</LoadingButton>
				</Stack>
			</form>
			<TextWithLink
				text={intl.formatMessage({ id: "app.rememberPassword" })}
				linkHref={pages.login.url()}
				buttonText={intl.formatMessage({ id: "app.signIn" })}
			/>
		</Stack>
	);
};

export default injectIntl(PasswordResetStep1);

import { FunctionComponent } from "react";
import { useAppSelector } from "hooks";
import { useTheme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
	IconButton,
	Toolbar,
	AppBar,
	Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UserMenu from "./UserMenu/UserMenu";
import LanguageMenu from "./LanguageMenu/LanguageMenu";

const ToolbarMiddle = styled("div")(({ theme }) => ({
	margin: "0 auto",
	display: "flex",
	alignItems: "center",
	maxWidth: 600,
	[theme.breakpoints.down("sm")]: {
		maxWidth: 340
	}
}));

type AppHeaderProps = {
	onToggleNavigation?: () => void;
}

const AppHeader: FunctionComponent<AppHeaderProps> = ({ onToggleNavigation }) => {
	const theme = useTheme();
	const isMobileNavDisplayed = useMediaQuery(theme.breakpoints.down("md"));
	const isAuthenticated = useAppSelector(
		(state) => state.auth.isAuthenticated
	);

	const showMenuButton = onToggleNavigation !== undefined;

	return (
		<>
			<AppBar>
				<Toolbar>
					{showMenuButton && isAuthenticated && !isMobileNavDisplayed && (
						<div style={{ marginRight: "auto" }}>
							<IconButton
								edge="start"
								color="inherit"
								aria-label="menu"
								onClick={onToggleNavigation}
							>
								<MenuIcon />
							</IconButton>
						</div>
					)}
					<ToolbarMiddle>
						<Typography variant="h6">Spicify</Typography>
					</ToolbarMiddle>
					{isAuthenticated && <UserMenu />}
					<LanguageMenu />
				</Toolbar>
			</AppBar>
			<Toolbar />
		</>
	);
};

export default AppHeader;

import { FunctionComponent, useEffect, useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useParams } from "react-router-dom";
import {
	Typography,
	FormControl,
	FormLabel,
	FormGroup,
	Chip,
	Stack
} from "@mui/material";
import { TagGroups, tagsToFormControls } from "helpers/tags";
import ContentPage from "components/common/ContentPage/ContentPage";
import { pages } from "pages";
import Recipe from "./Recipe";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { getNumberParam } from "helpers/views";
import { useGetDishQuery } from "store/apis/dish";

type DishViewParams = {
	dishId?: string;
};

const DishView: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const params = useParams<DishViewParams>();
	const [ dishId, paramError ] = getNumberParam(params.dishId, "dishId", intl);

	const { data: dish, error, isLoading}  = useGetDishQuery({ workspaceId, dishId: dishId || 0 }, {
		skip: !dishId
	})
	const [formattedTags, setFormattedTags] = useState<TagGroups | null>(null);

	useEffect(() => {
		if (dish) {
			setFormattedTags(tagsToFormControls(dish.tags || []));
		}
	}, [ dish ]);

	return (
		<ContentPage
			title={dish?.name ||  ""}
			editLink={pages.workspace.dishEdit.url(workspaceId, dish?.id || 0)}
			closeLink={pages.workspace.dishes.url(workspaceId)}
			loading={isLoading}
			error={paramError || (typeof error === "string" ? error : undefined)}
		>
			<Stack spacing={2} maxWidth={300} sx={{ mx: "auto" }}>
				{dish?.recipe && (<Recipe recipe={dish?.recipe} />)}
				{dish?.kilocalories ? (
					<Typography
						variant="body1"
					>
						{intl.formatMessage({ id: "app.kilocalories" }) +
							":" +
							` ${dish.kilocalories}`}
					</Typography>
				) : null}
				{formattedTags?.length ? (
					<FormControl component="fieldset" fullWidth>
						<FormLabel sx={{ textAlign: "center" }}>
							{intl.formatMessage({ id: "app.tags" })}
						</FormLabel>
						{formattedTags.map((tag) => (
							<FormControl
								aria-label="position"
								key={tag.groupName}
								sx={{ margin: "0 auto" }}
							>
								<FormLabel sx={{ textAlign: "center" }}>
									{tag.groupName}
								</FormLabel>
								<FormGroup row>
									{tag.values.map((tagValue) => {
										return (
											<Chip
												key={tagValue.name}
												label={
													<Typography
														variant="caption"
														sx={{
															whiteSpace:
																"normal"
														}}
													>
														{tagValue.name}
													</Typography>
												}
												size="small"
												sx={{
													color: tagValue.font_color,
													backgroundColor:
														tagValue.background_color,
													m: 0.5
												}}
											/>
										);
									})}
								</FormGroup>
							</FormControl>
						))}
					</FormControl>
				) : null}
			</Stack>
		</ContentPage>
	);
};

export default injectIntl(DishView);

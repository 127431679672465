import { CircularProgress, Button } from "@mui/material";
import { ComponentProps, FunctionComponent } from "react";

type LoadingButtonProps = ComponentProps<
  typeof Button
> & {
	isLoading?: boolean
};

const LoadingButton: FunctionComponent<LoadingButtonProps> = ({
	isLoading,
	children,
	...buttonProps
}) => {
	return (
		<>
			<Button {...buttonProps} disabled={isLoading}>
				{isLoading ? (
					<CircularProgress
						size={23}
						sx={{ margin: "0 auto" }}
					/>
				) : (
					children
				)}
			</Button>
		</>
	);
};

export default LoadingButton;

import { FunctionComponent, ReactNode } from "react";
import { Link, To, useNavigate } from "react-router-dom";
import {
	IconButton,
	Typography,
	Divider,
	Breadcrumbs,
	Button,
	Box,
	Fab,
	useMediaQuery,
	useTheme,
	Tooltip
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { Add, SortByAlpha } from "@mui/icons-material";

export type Breadcrumb = {
	label: string;
	url: string;
}

type LinkDetails = {
	href: To;
	text: string;
}
type ContentPageHeaderProps = {
	breadcrumbs?: Breadcrumb[];
	title?: string;
	titleComponent?: ReactNode;
	button?: LinkDetails;
	backLink?: string;
	editLink?: string;
	closeLink?: string;
	settingsLink?: string;
	filterLink?: string;
	sortingLink?: string;
};

const ContentPageHeader: FunctionComponent<ContentPageHeaderProps> = ({
	breadcrumbs = [],
	title = undefined,
	titleComponent = undefined,
	button = undefined,
	backLink = undefined,
	editLink = undefined,
	closeLink = undefined,
	settingsLink = undefined,
	filterLink = undefined,
	sortingLink = undefined
}) => {

	const showIcons = editLink || closeLink || settingsLink || filterLink || sortingLink;
	
	const theme = useTheme();
	const showFab = useMediaQuery(theme.breakpoints.down("md"));
	const navigate = useNavigate();
	return (
		<>
			<Box component="div" sx={{ display: "flex", flexDirection: "rows", pt: 1 }}>
				{backLink && (
					<IconButton component={Link} to={backLink}>
						<ArrowBackIcon />
					</IconButton>
				)}
				{!titleComponent && title && (
					<Typography variant="h5">{title}</Typography>
				)}
				{titleComponent && (
					<>{titleComponent}</>
				)}
				{!!button && (
					<>
						{!showFab ? <Link to={button.href}>
							<Button
								color="primary"
								variant="contained"
								sx={{ ml: 2, display: { sm: "none", md: "block" } }}
							>
								{button.text}
							</Button>
						</Link> :
						<Fab color="primary" aria-label="add" sx={{ position: "fixed", bottom: "70px", right: "16px"}}>
							<Tooltip title={button.text} placement="left-start">
								<Add onClick={() => navigate(button.href)} />
							</Tooltip>
						</Fab>}
					</>
				)}
				{showIcons && (
					<div style={{
						marginLeft: 'auto'
					}}>
						{editLink && (
						<IconButton component={Link} to={editLink}>
							<EditIcon />
						</IconButton>
						)}
						{sortingLink && (
						<IconButton component={Link} to={sortingLink}>
							<SortByAlpha />
						</IconButton>
						)}
						{filterLink && (
						<IconButton component={Link} to={filterLink}>
							<FilterAltIcon />
						</IconButton>
						)}
						{settingsLink && (
						<IconButton component={Link} to={settingsLink}>
							<SettingsIcon />
						</IconButton>
						)}
						{closeLink && (
						<IconButton component={Link} to={closeLink}>
							<CloseIcon />
						</IconButton>
						)}
					</div>
				)}
			</Box>
			{breadcrumbs.length > 0 && (
				<Breadcrumbs separator=">" sx={{ pt: 1 }} >
					{breadcrumbs.map(b => (
						<Link key={b.url} to={b.url}>
							{b.label}
						</Link>
					))}
				</Breadcrumbs>
			)}
			<Divider sx={{ pt: 1 }} />
		</>
	);
};

export default ContentPageHeader;

import { FunctionComponent, useEffect, useState } from "react";
import ShareEmailForm from "./ShareEmailForm/ShareEmailForm";
import {
	Typography,
	Divider,
	FormControl,
	Select,
	MenuItem
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ShareEmailList from "./ShareEmailList/ShareEmailList";
import InvitationList from "./InvitationList/InvitationList";
import { toast } from "react-toastify";
import SimpleDialog from "components/common/SimpleDialog/SimpleDialog";
import { shareAccessLevel } from "consts";
import { AccessLevel } from "@s6e/spicify-api-sdk-js";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { useDeleteShareMutation, useGetSharesQuery, useUpdateShareMutation } from "../../../../store/apis/share";
import {
	useDeleteInvitationMutation,
	useGetInvitationsQuery,
	useUpdateInvitationMutation
} from "../../../../store/apis/invitation";
import { errorString } from "../../../../helpers/api";
import ContentLoader from "components/common/ContentPage/ContentLoader";

const ShareEmail: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const [isDeleteShareDialogOpened, setDeleteShareDialogOpened] =
		useState(false);
	const [isEditShareDialogOpened, setEditShareDialogOpened] = useState(false);
	const [chosenShareId, setChosenShareId] = useState<number | null>(null);
	const [isDeleteInvitationDialogOpened, setDeleteInvitationDialogOpened] =
		useState(false);
	const [isEditInvitationDialogOpened, setEditInvitationDialogOpened] =
		useState(false);
	const [chosenInvitationId, setChosenInvitationId] = useState<
		number | null
	>(null);
	const [newAccessLevel, setNewAccessLevel] = useState<AccessLevel | null>(
		null
	);

	const { data: shares, isLoading, error } = useGetSharesQuery(workspaceId);
	const [
		updateShare,
		{
			isSuccess: isShareUpdateSuccess,
			isError: isShareUpdateError,
			error: shareUpdateError,
			isLoading: isShareUpdateInProgress
		}
	] = useUpdateShareMutation();

	const [
		deleteShare,
		{
			isSuccess: isShareDeleteSuccess,
			isError: isShareDeleteError,
			error: shareDeleteError,
			isLoading: isShareDeleteInProgress,
		}
	] = useDeleteShareMutation();

	const { data: invitations, isLoading: isLoadingInvitations, error: errorInvitations } = useGetInvitationsQuery(workspaceId);
	const [
		updateInvitation,
		{
			isSuccess: isInvitationUpdateSuccess,
			isError: isInvitationUpdateError,
			error: invitationUpdateError,
			isLoading: isInvitationUpdateInProgress,
		}
	] = useUpdateInvitationMutation();
	const [
		deleteInvitation,
		{
			isSuccess: isInvitationDeleteSuccess,
			isError: isInvitationDeleteError,
			error: invitationDeleteError,
			isLoading: isInvitationDeleteInProgress,
		}
	] = useDeleteInvitationMutation();

	const onDeleteShareClick = async () => {
		if (chosenShareId) {
			deleteShare({
				workspaceId,
				shareId: chosenShareId,
			});
		}
	};

	useEffect(() => {
		if (isShareDeleteSuccess || isShareDeleteError) {
			closeDeleteShareDialog();
		}
	}, [ isShareDeleteSuccess, isShareDeleteError ]);

	useEffect(() => {
		if (shareDeleteError) {
			toast.error(errorString(intl, shareDeleteError));
		}
	}, [ shareDeleteError, intl ]);

	const onEditShareSubmit = async () => {
		if (chosenShareId && newAccessLevel) {
			updateShare({
				workspaceId,
				shareId: chosenShareId,
				data: {
					access_level: newAccessLevel,
				}
			});
		}
	};

	useEffect(() => {
		if (isShareUpdateSuccess || isShareUpdateError) {
			closeEditShareDialog();
		}
	}, [ isShareUpdateSuccess, isShareUpdateError ]);

	useEffect(() => {
		if (shareUpdateError) {
			toast.error(errorString(intl, shareUpdateError));
		}
	}, [ shareUpdateError, intl ]);

	const onDeleteInvitation = async () => {
		if (chosenInvitationId && newAccessLevel) {
			deleteInvitation({
				workspaceId,
				invitationId: chosenInvitationId
			});
		}
	};

	useEffect(() => {
		if (isInvitationDeleteSuccess || isInvitationDeleteError) {
			closeDeleteInvitationDialog();
		}
	}, [ isInvitationDeleteSuccess, isInvitationDeleteError ]);

	useEffect(() => {
		if (invitationDeleteError) {
			toast.error(errorString(intl, invitationDeleteError));
		}
	}, [ invitationDeleteError, intl ]);

	const editInvitation = async () => {
		if (chosenInvitationId && newAccessLevel) {
			updateInvitation({
				workspaceId,
				invitationId: chosenInvitationId,
				data: {
					access_level: newAccessLevel,
				}
			});
		}
	};

	useEffect(() => {
		if (isInvitationUpdateSuccess || isInvitationUpdateError) {
			closeEditInvitationDialog();
		}
	}, [ isInvitationUpdateSuccess, isInvitationUpdateError ]);

	useEffect(() => {
		if (invitationUpdateError) {
			toast.error(errorString(intl, invitationUpdateError));
		}
	}, [ invitationUpdateError, intl ]);

	const closeDeleteShareDialog = () => {
		setDeleteShareDialogOpened(false);
	};

	const closeEditShareDialog = () => {
		setEditShareDialogOpened(false);
	};

	const closeDeleteInvitationDialog = () => {
		setDeleteInvitationDialogOpened(false);
	};

	const closeEditInvitationDialog = () => {
		setEditInvitationDialogOpened(false);
	};

	return (
		<>
			<ShareEmailForm workspaceId={workspaceId} />
			<div style={{ marginTop: "1rem" }}>
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.sharedWith" })}:
				</Typography>
			</div>
			<Divider />
			<ContentLoader loading={isLoading} error={errorString(intl, error)}>
				{shares ? (
					<>
						<ShareEmailList
							shares={shares}
							setChoosenShareId={setChosenShareId}
							setEditShareDialogOpened={setEditShareDialogOpened}
							setDeleteShareDialogOpened={setDeleteShareDialogOpened}
						/>
						<SimpleDialog
							open={isDeleteShareDialogOpened}
							onClose={closeDeleteShareDialog}
							dialogContent={intl.formatMessage({
								id: "app.removeShareConfirmation"
							})}
							cancelButtonText={intl.formatMessage({
								id: "app.cancel"
							})}
							onConfirmButton={onDeleteShareClick}
							confirmButtonText={intl.formatMessage({
								id: "app.delete"
							})}
							isConfirmInProgress={isShareDeleteInProgress}
						/>
						<SimpleDialog
							open={isEditShareDialogOpened}
							onClose={closeEditShareDialog}
							dialogContent={
								<FormControl
									style={{ width: 250 }}
								>
									<Select
										value={newAccessLevel || AccessLevel.View}
										onChange={(e) =>
											setNewAccessLevel(
												e.target.value as AccessLevel
											)
										}
									>
										{shareAccessLevel.map((level) => (
											<MenuItem
												key={level.value}
												value={level.value}
											>
												{intl.formatMessage({
													id: level.translationId
												})}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							}
							cancelButtonText={intl.formatMessage({
								id: "app.cancel"
							})}
							onConfirmButton={onEditShareSubmit}
							confirmButtonText={intl.formatMessage({
								id: "app.save"
							})}
							isConfirmInProgress={isShareUpdateInProgress}
						/>
					</>
				) : (
					<Typography variant="subtitle1">
						{intl.formatMessage({ id: "app.emptyShares" })}
					</Typography>
				)}
			</ContentLoader>
			<div style={{ marginTop: "1rem" }}>
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.awaitingInvitations" })}:
				</Typography>
			</div>
			<Divider />
			<ContentLoader loading={isLoadingInvitations} error={errorString(intl, errorInvitations)}>
			{invitations ? (
				<>
					<InvitationList
						invitations={invitations}
						setChoosenInvitationId={setChosenInvitationId}
						setEditInvitationDialogOpened={
							setEditInvitationDialogOpened
						}
						setDeleteInvitationDialogOpened={
							setDeleteInvitationDialogOpened
						}
					/>
					<SimpleDialog
						open={isDeleteInvitationDialogOpened}
						onClose={closeDeleteInvitationDialog}
						dialogContent={intl.formatMessage({
							id: "app.removeInvitationConfirmation"
						})}
						cancelButtonText={intl.formatMessage({
							id: "app.cancel"
						})}
						onConfirmButton={onDeleteInvitation}
						confirmButtonText={intl.formatMessage({
							id: "app.delete"
						})}
						isConfirmInProgress={isInvitationDeleteInProgress}
					/>
					<SimpleDialog
						open={isEditInvitationDialogOpened}
						onClose={closeEditInvitationDialog}
						dialogContent={
							<FormControl
								style={{ width: 250 }}
							>
								<Select
									value={newAccessLevel || 1}
									onChange={(e) =>
										setNewAccessLevel(
											e.target.value as AccessLevel
										)
									}
								>
									{shareAccessLevel.map((level) => (
										<MenuItem
											key={level.value}
											value={level.value}
										>
											{intl.formatMessage({
												id: level.translationId
											})}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						}
						cancelButtonText={intl.formatMessage({
							id: "app.cancel"
						})}
						onConfirmButton={editInvitation}
						confirmButtonText={intl.formatMessage({
							id: "app.save"
						})}
						isConfirmInProgress={isInvitationUpdateInProgress}
					/>
				</>
			) : (
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.emptyInvitations" })}
				</Typography>
			)}
			</ContentLoader>
		</>
	);
};

export default injectIntl(ShareEmail);

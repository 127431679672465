import { FunctionComponent, useRef, useState } from "react";
import { Dish } from "@s6e/spicify-api-sdk-js";
import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grow,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
	useMediaQuery,
	useTheme
} from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ArrowDropDown } from "@mui/icons-material";

type DishCardProps = {
	dish: Dish;
	onNameClick?: () => void;
	onFavoriteClick?: () => void;
	onUseClick?: () => void;
	onDropClick?: () => void;
	onEditClick?: () => void;
	onDeleteClick?: () => void;
};

const DishCard: FunctionComponent<DishCardProps & WrappedComponentProps> = ({
	dish,
	onFavoriteClick = undefined,
	onUseClick = undefined,
	onDropClick = undefined,
	onEditClick = undefined,
	onDeleteClick = undefined,
	intl
}) => {
	const theme = useTheme();
	const useSplitButton = useMediaQuery(theme.breakpoints.down("lg"));

	const [open, setOpen] = useState(false);
  	const anchorRef = useRef<HTMLDivElement>(null);

  	const handleMenuItemClick = (action?: () => void) => {
    	if (action) {
			action();
		}
    	setOpen(false);
  	};

  	const handleToggle = () => {
    	setOpen((prevOpen) => !prevOpen);
  	};

  	const handleClose = (event: Event) => {
    	if (
      		anchorRef.current &&
      		anchorRef.current.contains(event.target as HTMLElement)
    	) {
      		return;
    	}

		setOpen(false);
  	};

	return (
		<>
			<ButtonGroup ref={anchorRef}>
				{onUseClick && 
					<Button
						onClick={onUseClick}
						variant="outlined"
						color="primary"
					>
						{intl.formatMessage({ id: "app.useDish" })}
					</Button>
				}
				{!useSplitButton && onDropClick &&
					<Button
						onClick={onDropClick}
						variant="outlined"
						color="error"
					>
						{intl.formatMessage({ id: "app.dropDish" })}
					</Button>
				}
				{!useSplitButton && onFavoriteClick && 
					<Tooltip placement="left-end" title={intl.formatMessage({ id: !dish.favorite ? "app.addToFavorites" :  "app.removeFromFavorites"})}>
						<Button onClick={onFavoriteClick} color="secondary">
							{dish.favorite 
								? (<FavoriteIcon />)
								: (<FavoriteBorderIcon />)}
						</Button>
					</Tooltip>
				}
				{!useSplitButton && onEditClick &&
					<Tooltip placement="left-end" title={intl.formatMessage({ id: "app.edit" })}>
						<Button onClick={onEditClick} color="secondary">
							<EditIcon />
						</Button>
					</Tooltip>
				}
				{!useSplitButton && onDeleteClick &&
					<Tooltip placement="left-end" title={intl.formatMessage({ id: "app.delete" })}>
						<Button onClick={onDeleteClick} color="secondary">
							<DeleteIcon />
						</Button>
					</Tooltip>
				}
				{useSplitButton && <Button
					size="small"
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDown />
				</Button>
				}
			</ButtonGroup>
			<Popper
				sx={{ zIndex: 1 }}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
					transformOrigin:
						placement === 'bottom' ? 'center top' : 'center bottom',
					}}
				>
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<MenuList autoFocusItem>
							{onDropClick && <MenuItem onClick={() => handleMenuItemClick(onDropClick)}>
								<ListItemIcon>
									<ThumbDownIcon fontSize="small" color="error" />
								</ListItemIcon>
								<ListItemText color="error">{intl.formatMessage({ id: "app.dropDish" })}</ListItemText>
							</MenuItem>}
							{onFavoriteClick && <MenuItem onClick={() => handleMenuItemClick(onFavoriteClick)}>
								<ListItemIcon>
									{dish.favorite 
										? (<FavoriteIcon fontSize="small" />)
										: (<FavoriteBorderIcon fontSize="small" />)}
								</ListItemIcon>
								<ListItemText color="error">{intl.formatMessage({ id: !dish.favorite ? "app.addToFavorites" :  "app.removeFromFavorites"})}</ListItemText>
							</MenuItem>}
							{onEditClick && <MenuItem onClick={() => handleMenuItemClick(onEditClick)}>
								<ListItemIcon>
									<EditIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText>{intl.formatMessage({ id: "app.edit"})}</ListItemText>
							</MenuItem>}
							{onDeleteClick && <MenuItem onClick={() => handleMenuItemClick(onDeleteClick)}>
								<ListItemIcon>
									<DeleteIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText>{intl.formatMessage({ id: "app.delete"})}</ListItemText>
							</MenuItem>}
						</MenuList>
					</ClickAwayListener>
					</Paper>
				</Grow>
				)}
			</Popper>
		</>
	);
};

export default injectIntl(DishCard);

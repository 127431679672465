import { FunctionComponent, MouseEventHandler, ReactNode } from "react";
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button
} from "@mui/material";
import SubmitButton from "../SubmitButton";

type SimpleDialogProps = {
	open: boolean;
	onClose: MouseEventHandler<HTMLElement>;
	dialogContent: ReactNode;
	onConfirmButton: MouseEventHandler<HTMLElement>;
	confirmButtonText: ReactNode;
	cancelButtonText: ReactNode;
	isConfirmInProgress: boolean;
};

const SimpleDialog: FunctionComponent<SimpleDialogProps> = ({
	open,
	onClose,
	dialogContent,
	onConfirmButton,
	confirmButtonText,
	cancelButtonText,
	isConfirmInProgress
}) => (
	<Dialog open={open} onClose={onClose}>
		<DialogContent>{dialogContent}</DialogContent>
		<DialogActions>
			<Button onClick={onClose}>{cancelButtonText}</Button>
			<SubmitButton isLoading={isConfirmInProgress} onClick={onConfirmButton}>
				{confirmButtonText}
			</SubmitButton>
		</DialogActions>
	</Dialog>
);

export default SimpleDialog;

import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	IconButton
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	injectIntl,
	WrappedComponentProps
} from "react-intl";
import { pages } from "pages";
import TagDeleteDialog from "./TagDeleteDialog";
import { Tag } from "@s6e/spicify-api-sdk-js";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { useDeleteTagMutation, useGetTagsQuery } from "../../../store/apis/tag";
import { errorString } from "../../../helpers/api";
import ContentPage from "components/common/ContentPage/ContentPage";

const TagsPage: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({
	workspaceId,
	intl
}) => {
	const [isDialogOpened, setDialogOpened] = useState(false);
	const [tagToDelete, setTagToDelete] = useState<Tag | null>(null);

	const { data: tags, isLoading, error } = useGetTagsQuery(workspaceId);
	const [
		deleteTag,
		{
			isSuccess: isTagDeleteSuccess,
			isError: isTagDeleteError,
			error: tagDeleteError,
			isLoading: isTagDeleteInProgress
		}
	] = useDeleteTagMutation()

	const onConfirmDeleteTag = async () => {
		if (tagToDelete) {
			deleteTag({
				workspaceId,
				tagId: tagToDelete.id,
			});
		}
	};

	useEffect(() => {
		if (isTagDeleteSuccess || isTagDeleteError) {
			closeDialog();
		}
	}, [ isTagDeleteSuccess, isTagDeleteError ]);

	useEffect(() => {
		if (tagDeleteError) {
			toast.error(errorString(intl, tagDeleteError));
		}
	}, [ tagDeleteError, intl ])

	const closeDialog = () => {
		setDialogOpened(false);
	};

	return (
		<ContentPage
			loading={isLoading}
			error={errorString(intl, error)}
			title={intl.formatMessage({ id: "app.tags" })}
			button={{ text: intl.formatMessage({ id: "app.create" }), href: pages.workspace.tagCreate.url(workspaceId)}}
		>
			{tags ?
				<List sx={{ flexGrow: 4 }} >
					{tags.map((tag: Tag) => {
						return (
							<ListItem key={tag.id}
								divider
								secondaryAction={
									<>
										<IconButton
											component={Link}
											to={pages.workspace.tagEdit.url(workspaceId, tag.id)}
										>
											<EditIcon color="primary" />
										</IconButton>
										<IconButton
											onClick={() => {
												setTagToDelete(tag);
												setDialogOpened(true);
											}}
										>
											<DeleteIcon color="secondary" />
										</IconButton>
									</>
								}
								>
								<ListItemText
									primary={tag.name}
									secondary={tag.group}
								/>
							</ListItem>
						);
					})}
				</List>
			: <Typography variant="h5">
				{intl.formatMessage({ id: "app.emptyTags" })}
			</Typography>}
			{tagToDelete !== null && (
			<TagDeleteDialog
				deleteInProgress={isTagDeleteInProgress}
				isOpen={isDialogOpened}
				tag={tagToDelete}
				onClose={closeDialog}
				onDelete={onConfirmDeleteTag}
			/>)}
		</ContentPage>
	);
};

export default injectIntl(TagsPage);

import { IntlShape } from "react-intl";
import { FieldValues, UseFormWatch } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { emailPattern } from "./consts";

const minUsernameLength = 3;
const minPasswordLength = 6;
const minGroups = 3;

export function usernameValidation<TFieldName extends string>(intl: IntlShape) : RegisterOptions<FieldValues, TFieldName> {
	return {
		required: intl.formatMessage({ id: "app.usernameIsRequired" }),
		minLength: {
			value: minUsernameLength,
			message: intl.formatMessage({ id: "app.minUsernameLength" }, { "minLength": minUsernameLength })
		},
		validate: (val: string) => {
			const containsOnlyAllowed = /^[a-zA-Z0-9.-_]+$/.test(val);
			const startsWithAllowed = /^[a-zA-Z0-9]/.test(val);
			if (!containsOnlyAllowed || !startsWithAllowed) {
				return intl.formatMessage({ id: "app.usernameNotMatchRequirements" })
			}
			return true;
		}
	}
}

export function emailValidation<TFieldName extends string>(intl: IntlShape) : RegisterOptions<FieldValues, TFieldName> {
	return {
		required: intl.formatMessage({ id: "app.emailIsRequired" }),
		pattern: {
			value: emailPattern,
			message: intl.formatMessage({ id: "app.incorrectEmailAddress" })
		}
	};
}

export function passwordValidation<TFieldName extends string>(intl: IntlShape) : RegisterOptions<FieldValues, TFieldName> {
	return {
		required: intl.formatMessage({ id: "app.emailIsRequired" }),
		minLength: {
			value: minPasswordLength,
			message: intl.formatMessage({ id: "app.minPasswordLength" }, { "minLength": minPasswordLength })
		},
		validate: (val: string) => {
			if (!isStrongPassword(val, minGroups)) {
				return intl.formatMessage({ id: "app.weakPassword" }, { "minGroups": minGroups })
			}
			return true;
		}
	}
}

export function confirmPasswordValidation<TFieldName extends string>(intl: IntlShape, watch: UseFormWatch<FieldValues>) : RegisterOptions<FieldValues, TFieldName> {
	return {
		required: intl.formatMessage({ id: "app.emailIsRequired" }),
		minLength: {
			value: minPasswordLength,
			message: intl.formatMessage({ id: "app.minPasswordLength" }, { "minLength": minPasswordLength })
		},
		validate: (val: string) => {
			if (val !== watch("password")) {
				return intl.formatMessage({ id: "app.notMatchingPassword" })
			}
			return true;
		}
	};
}

export function isStrongPassword(password: string, minGroupsCount: number): boolean
{
	let containsSmallLetters = false;
	let containsCapitalLetters = false;
	let containsNumbers = false;
	let containsSpecialCharacters = false;
	for (let i = 0; i < password.length; i++) {
		if (password[i] >= 'a' && password[i] <= 'z') {
			containsSmallLetters = true;
		} else if (password[i] >= 'A' && password[i] <= 'Z') {
			containsCapitalLetters = true;
		} else if (password[i] >= '0' && password[i] <= '9') {
			containsNumbers = true;
		} else {
			containsSpecialCharacters = true;
		}

		if (containsSmallLetters && containsCapitalLetters && containsNumbers && containsSpecialCharacters) {
			break;
		}
	}

	const numberOfGroups =
		Number(containsSmallLetters) +
		Number(containsCapitalLetters) +
		Number(containsNumbers) +
		Number(containsSpecialCharacters);

	return numberOfGroups >= minGroupsCount;
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defineLanguage, Translation } from "helpers/language";
import { RootState } from "store/store";
import { languageStorageKey } from "consts";

interface LanguageState {
	language: Translation;
}

const initialState: LanguageState = {
	language: defineLanguage(),
};

export const selectLanguage = (state: RootState): Translation => state.language.language;

export const languageSlice = createSlice({
	name: "language",
	initialState,
	reducers: {
		changeLanguage: (state, action: PayloadAction<string>) => {
			localStorage.setItem(languageStorageKey, action.payload);
			state.language = defineLanguage();
		}
	}
});

const languageReducer = languageSlice.reducer;

export const { changeLanguage } = languageSlice.actions;

export default languageReducer;

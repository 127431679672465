import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import {
	FormControlLabel,
	Checkbox,
	FormControl,
	FormHelperText,
	Stack,
	TextField
} from "@mui/material";
import Logo from "components/common/Logo/Logo";
import TextWithLink from "components/common/TextWithLink/TextWithLink";
import LoadingButton from "components/common/LoadingButton/LoadingButton";
import { pages } from "pages";

import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";
import { useRegisterUserMutation } from "../../../store/apis/auth";
import { confirmPasswordValidation, emailValidation, passwordValidation, usernameValidation } from "../../../validators";

type RegisterFormSubmit = (payload: FieldValues) => Promise<void>;

const Register: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		watch
	} = useForm();

	const navigate = useNavigate();

	const [ registerUser, { isLoading, error, isSuccess }] = useRegisterUserMutation();

	const submitForm: RegisterFormSubmit = async ({
		username,
		email,
		password,
		terms
	}) => {
		const newUser = {
			username,
			email,
			plain_password: password,
			agree_terms: terms
		};
		registerUser(newUser);
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success(
				`🙌 ${intl.formatMessage({ id: "app.successRegister" })}`
			);
			navigate(pages.login.url());
		}
	}, [ isSuccess, navigate, intl ]);

	return (
		<Stack maxWidth={300} sx={{ mx: "auto" }} justifyContent="center" alignContent="center">
			<Logo />
			<form onSubmit={handleSubmit(submitForm)}>
				<Stack spacing={2}>
					<TextField
						label={intl.formatMessage({ id: "app.username" })}
						{...register("username", usernameValidation(intl))}
						helperText={errors.username?.message?.toString() || ''}
						error={!!errors.username}
						fullWidth
					/>
					<TextField
						label={intl.formatMessage({ id: "app.email" })}
						{...register("email", emailValidation(intl))}
						helperText={errors.email?.message?.toString() || ''}
						error={!!errors.email}
						fullWidth
					/>
					<TextField
						label={intl.formatMessage({ id: "app.password" })}
						type="password"
						{...register("password", passwordValidation(intl))}
						helperText={errors.password?.message?.toString() || ''}
						error={!!errors.password}
						fullWidth
					/>
					<TextField
						label={intl.formatMessage({ id: "app.confirmPassword" })}
						type="password"
						{...register("confirmPassword", confirmPasswordValidation(intl, watch))}
						helperText={errors.confirmPassword?.message?.toString() || ''}
						error={!!errors.confirmPassword}
						fullWidth
					/>
					<FormControl error={!!errors.terms}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									{...register("terms",
										{
											required: intl.formatMessage({ id: "app.termsRequired" })
										})}
								/>
							}
							label={
								<>
									<span>{intl.formatMessage({ id: "app.iAccept" })} </span>
									<a
										rel="noopener noreferrer"
										target="_blank"
										href={window._env_.SPICIFY_TERMS_OF_USE_URL}
									>
										{intl.formatMessage({ id: "app.termsOfUse" })}
									</a>
									<span> {intl.formatMessage({ id: "app.and" })} </span>
									<a
										rel="noopener noreferrer"
										target="_blank"
										href={window._env_.SPICIFY_PRIVACE_POLICY_URL}
									>
										{intl.formatMessage({ id: "app.privacyPolicy" })}
									</a>
								</>
							}	
						/>
						{errors.terms && (
							<FormHelperText>{errors.terms?.message?.toString() || ''}</FormHelperText>
						)}
					</FormControl>

					<ContentError error={errorString(intl, error)} />
					<LoadingButton
						isLoading={isLoading}
						type="submit"
						color="primary"
						variant="contained"
					>
						{intl.formatMessage({ id: "app.signUp" })}
					</LoadingButton>
				</Stack>
			</form>

			<TextWithLink
				text={intl.formatMessage({ id: "app.alreadyHaveAccount" })}
				linkHref={pages.login.url()}
				buttonText={intl.formatMessage({ id: "app.signIn" })}
			/>
		</Stack>
	);
};

export default injectIntl(Register);

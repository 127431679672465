import { FunctionComponent } from "react";
import {
	Button,
	Avatar,
	Chip,
	Stack,
	Box,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { injectIntl, WrappedComponentProps } from "react-intl";
import api, { User } from "@s6e/spicify-api-sdk-js";
import ContentPage from "components/common/ContentPage/ContentPage";
import { pages } from "pages";
import { errorString } from "../../../helpers/api";
import { useGetUserQuery } from "../../../store/apis/auth";
import { useNavigate } from "react-router-dom";

function stringAvatar(user: User | undefined): string | undefined {
	return user?.display_name?.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '').toUpperCase()
}

const ProfilePage: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const navigate = useNavigate();

	const { data: user, isLoading, error } = useGetUserQuery();

	const handleSignOut = () => {
		api.logout();
		navigate(pages.main.url());
	};

	return (
		<ContentPage
			loading={isLoading}
			error={errorString(intl, error)}
			title={intl.formatMessage({ id: "app.profile" })}
			closeLink={pages.main.url()}
		>
			<Stack spacing={2} maxWidth={320} sx={{ p: 1, mx: "auto" }}>
				<Box component="div" sx={{ display: "flex", justifyContent: "center" }}>
					<Avatar sx={{ margin: "0 auto", width: 128, height: 128, fontSize: "92px" }}>{stringAvatar(user)}</Avatar>
				</Box>
				<h3 style={{ textAlign: "center", marginTop: "10px" }}>{user?.username}</h3>
				<Box component="div" sx={{ display: "flex", justifyContent: "center" }}>
					<Chip label={user?.plan?.name} variant="outlined" />
				</Box>
				<h4>{intl.formatMessage({ id: "app.email" })}</h4>
				<span>{user?.email}</span>

				<Button
					startIcon={<PowerSettingsNewIcon />}
					color="secondary"
					size="large"
					onClick={handleSignOut}
				>
					{intl.formatMessage({ id: "app.logout" })}
				</Button>
			</Stack>
		</ContentPage>
	);
};

export default injectIntl(ProfilePage);

import {
	createSlice,
	PayloadAction
} from "@reduxjs/toolkit";


interface LayoutState {
	isNavbarOpen: boolean;
}

const initialState: LayoutState = {
	isNavbarOpen: true,
};

export const layoutSlice = createSlice({
	name: "layout",
	initialState,
	reducers: {
		setNavbarOpen: (state, action: PayloadAction<boolean>) => {
			state.isNavbarOpen = action.payload;
		}
	}
});

export const { setNavbarOpen } = layoutSlice.actions;

const layoutReducer = layoutSlice.reducer;

export default layoutReducer;

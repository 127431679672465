import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
	Button,
	FormControl,
	Grid2,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { DishOrder, OrderDirection } from "@s6e/spicify-api-sdk-js";
import { Add } from "@mui/icons-material";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";
import SubmitButton from "components/common/SubmitButton";

type DishSortingFormSubmit = (payload: FieldValues) => void;
export type DishSortingFormProps = {
	sorting: DishOrder,
	onSubmit: DishSortingFormSubmit,
	error?: unknown,
	closeLink: string,
	processing?: boolean
}

const DishSortingForm: FunctionComponent<DishSortingFormProps & WrappedComponentProps> = ({
	sorting,
	onSubmit,
	error,
	closeLink,
	processing,
	intl
}) => {
	const [formData, setFormData] = useState({rows: sorting})
	const addClick = () => {
		setFormData(prev => ({
			rows: prev.rows.concat([{
				field: "name",
				direction: OrderDirection.Ascending
			}]) 
		}))
	  }

	const {
		handleSubmit,
		control,
	} = useForm({ 
		shouldUnregister: true,
		defaultValues: formData
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={1}>
				<Grid2 container columns={2} spacing={2}>
				{formData.rows && formData.rows.map((_, index) => (	
					<>
						<Grid2 size={1}>
							<FormControl fullWidth>
								<InputLabel>
									{intl.formatMessage({ id: "app.order_by" })}
								</InputLabel>
								<Controller
									render={({ field }) => (
										<Select
											label={intl.formatMessage({
												id: "app.order_by"
											})}
											sx={{ textAlign: "left" }}
											{...field}
										>
											<MenuItem value="name">
												{intl.formatMessage({
													id: "app.name"
												})}
											</MenuItem>
											<MenuItem value="recipe">
												{intl.formatMessage({
													id: "app.recipe"
												})}
											</MenuItem>
											<MenuItem value="time_effort">
												{intl.formatMessage({
													id: "app.time_effort"
												})}
											</MenuItem>
										</Select>
									)}
									name={`rows.${index}.field`}
									control={control}
								/>
							</FormControl>
						</Grid2>
						<Grid2 size={1}>
							<FormControl fullWidth>
								<InputLabel>
									{intl.formatMessage({ id: "app.order_direction" })}
								</InputLabel>
								<Controller
									render={({ field }) => (
										<Select
											label={intl.formatMessage({
												id: "app.order_direction"
											})}
											sx={{ textAlign: "left" }}
											{...field}
										>
											<MenuItem value={OrderDirection.Ascending}>
												{intl.formatMessage({
													id: "app.ascending"
												})}
											</MenuItem>
											<MenuItem value={OrderDirection.Descending}>
														{intl.formatMessage({
													id: "app.descending"
												})}
											</MenuItem>
										</Select>
									)}
									name={`rows.${index}.direction`}
									control={control}
								/>
							</FormControl>
						</Grid2>
					</>
				))}
				</Grid2>
				<div>
					<IconButton onClick={addClick}>
						<Add />
					</IconButton>
				</div>

				<ContentError error={errorString(intl, error)} />		

				<Stack direction="row" spacing={2} justifyContent="flex-end">
					<Button
						color="secondary"
						component={Link}
						to={closeLink}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton isLoading={processing}>
						{intl.formatMessage({ id: "app.save" })}
					</SubmitButton>
				</Stack>
			</Stack>
		</form>
	);
};

export default injectIntl(DishSortingForm);

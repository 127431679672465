import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Box, useMediaQuery } from "@mui/material";
import ShareLink from "./ShareLink/ShareLink";
import ShareEmail from "./ShareEmail/ShareEmail";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { useGetWorkspaceQuery } from "../../../store/apis/workspace";
import { errorString } from "../../../helpers/api";
import ContentPage from "components/common/ContentPage/ContentPage";
import { useTheme } from "@mui/material/styles";

const SharePage: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({
	workspaceId,
	intl
}) => {
	const navigate = useNavigate();
	const { data: workspace, error, isLoading } = useGetWorkspaceQuery(workspaceId);
	const theme = useTheme();
	const sideBySide = useMediaQuery(theme.breakpoints.up("lg"));

	useEffect(() => {
		if (workspace?.personal) {
			navigate(pages.main.url());
			return;
		}
	}, [ workspace, navigate ]);

	if (error) {
		return <ContentError error={errorString(intl, error)} />
	}

	return (
		<Box component="div" className="Spicify-DishesPage-root" sx={{ display: "flex", flexDirection: "column", flexGrow: 1, overflowY: "scroll" }}>
			{workspace && <ContentPage
				loading={isLoading}
				error={errorString(intl, error)}
				title={intl.formatMessage({ id: "app.share" })}
				closeLink={pages.workspace.suggested.url(workspace.id)}
			>
				{sideBySide && (<>
				<Box component="div" className="Spicify-DishesPage-root" sx={{
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
					overflowY: "scroll",
					p: 1
				}}>
					<ShareEmail workspaceId={workspaceId} />
				</Box>
				<Box component="div" className="Spicify-DishesPage-root" sx={{ display: "flex", flexDirection: "column", flexGrow: 1, overflowY: "scroll", p: 1 }}>
					<ShareLink workspaceId={workspaceId} />
				</Box>
				</>)}

				{!sideBySide && (<>
				<Box component="div" className="Spicify-DishesPage-root" sx={{
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
					overflowY: "scroll",
					p: 1
				}}>
					<ShareEmail workspaceId={workspaceId} />
					<ShareLink workspaceId={workspaceId} />
				</Box>
				</>)}
			</ContentPage>}
		</Box>
	);
};

export default injectIntl(SharePage);

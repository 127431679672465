import { FunctionComponent, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { setNavbarOpen } from "store/reducers/layout/layoutReducer";
import AppHeader from "./AppHeader";
import { Box } from "@mui/material";

type LayoutProps = {
	children: ReactNode;
};

const Layout: FunctionComponent<LayoutProps> = ({
	children
}) => {
	const dispatch = useAppDispatch();
	const isNavbarOpen = useAppSelector(
		(state) => state.layout.isNavbarOpen
	);

	return (
		<Box component="div" className="Spicify-Layout-root" sx={{ margin: 0, height: "100vh", display: "flex", flexDirection: "column" }}>
			<Box component="div" className="Spicify-Layout-header" sx={{ flex: "none" }}>
				<AppHeader onToggleNavigation={() => dispatch(setNavbarOpen(!isNavbarOpen))} />
			</Box>
			<Box component="div" className="Spicify-Layout-main" sx={{ flexGrow: 1, display: "flex", flexDirection: 'row', overflowY: "scroll" }}>
				{children}
			</Box>
		</Box>
	);
};

export default Layout;

import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { DishFilter } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { selectFilter, setFilter } from "store/reducers/dishes/dishesReducer";
import DishFilterForm from "./DishFilterForm";
import { Box } from "@mui/material";

const DishFilterView: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const closeLink = pages.workspace.dishes.url(workspaceId);

	const filter = useAppSelector(selectFilter);

	const onSubmit = async (formData: FieldValues) => {
		const dataToSend: DishFilter = {
			name: formData.name !== "" ? formData.name : undefined,
			recipe: formData.recipe !== "" ? formData.recipe : undefined,
			time_effort: formData.time_effort !== "" ? Number(formData.time_effort) : undefined,
			spring: formData.spring ? formData.spring === 1 : undefined,
			summer: formData.summer ? formData.summer === 1 : undefined,
			autumn: formData.autumn ? formData.autumn === 1 : undefined,
			winter: formData.winter ? formData.winter === 1 : undefined,
		};

		dispatch(setFilter(dataToSend));
		navigate(closeLink);
	};

	const breadcrumbs = [
		{
			label: intl.formatMessage({ id: "app.dishes" }),
			url: closeLink,
		}
	]

	return (
		<ContentPage
			breadcrumbs={breadcrumbs}
			title={intl.formatMessage({ id: "app.dish_filter" })}
			closeLink={closeLink}
		>
			<Box component="div" sx={{ flexGrow: 4, mx: "auto", mt: 2, maxWidth: "700px" }}>
				<DishFilterForm
					onSubmit={onSubmit}
					closeLink={closeLink}
					filter={filter}
				/>
			</Box>
		</ContentPage>
	);
};

export default injectIntl(DishFilterView);

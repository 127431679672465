import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import {
	Box
} from "@mui/material";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { selectQuery, setQuery } from "store/reducers/generator/generatorReducer";
import { GenerateQuery, TagValue } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { errorString } from "helpers/api";
import { useGetTagsQuery } from "../../../../store/apis/tag";
import GeneratorFilterForm from "./GeneratorFilterForm";


const GeneratorFilterPage: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const closeLink = pages.workspace.suggested.url(workspaceId);

	const { data: tags, error, isLoading } = useGetTagsQuery(workspaceId);

	const query = useAppSelector(selectQuery);

	const onSubmit = async (formData: FieldValues) => {
		const dataToSend: GenerateQuery = {
			time_effort: formData.time_effort,
			season: Number(formData.season),
			tags: formData.tags.map((tag: TagValue) => ({
				id: Number(tag.id),
				value: Number(tag.value)
			}))
		};

		if (formData.kilocalories) {
			dataToSend.kilocalories = Number(formData.kilocalories);
		}

		dispatch(setQuery(dataToSend));
		navigate(closeLink);
	};

	const breadcrumbs = [
		{
			label: intl.formatMessage({ id: "app.suggested" }),
			url: pages.workspace.suggested.url(workspaceId),
		}
	]

	return (
		<ContentPage
			breadcrumbs={breadcrumbs}
			title={intl.formatMessage({ id: "app.suggestions_settings" })}
			closeLink={closeLink}
			loading={isLoading}
			error={errorString(intl, error)}
		>
			{tags && <Box component="div" sx={{ flexGrow: 4, mx: "auto", mt: 2, maxWidth: "700px" }}>
				<GeneratorFilterForm
					query={query}
					tags={tags}
					onSubmit={onSubmit}
					closeLink={pages.workspaces.url()}
					processing={false}
				/>
			</Box>}
		</ContentPage>
	);
};

export default injectIntl(GeneratorFilterPage);

import { ComponentProps, FunctionComponent, ReactNode } from "react";
import ContentPageHeader from "./ContentPageHeader";
import ContentLoader from "./ContentLoader";
import { Box } from "@mui/material";

type ContentPageProps = ComponentProps<
typeof ContentPageHeader
> & {
	loading?: boolean;
	error?: string;
	children?: ReactNode;
};

const ContentPage: FunctionComponent<ContentPageProps> = (props: ContentPageProps) => {

	const showIcons = props.editLink || props.closeLink || props.settingsLink || props.filterLink;
	const showHeader = showIcons || props.title || props.backLink || !!props.button;
	return (
		<Box component="div" className="Spicify-ContentPage-root" sx={{ display: "flex", flexGrow: 1, flexDirection: "column", overflowY: "scroll" }}>
			<Box component="div" className="Spicify-ContentPage-header" sx={{ flex: "none", p: 1 }}>
			{showHeader && (
				<ContentPageHeader {...props} />
			)}
			</Box>
			<Box component="div" className="Spicify-ContentPage-content" sx={{ flexGrow: 1, display: "flex", flexDirection: "row", overflowY: "scroll", p: 1 }}>
				<ContentLoader loading={props.loading} error={props.error}>
					{props.children}
				</ContentLoader>
			</Box>
		</Box>
	);
};

export default ContentPage;

import { ComponentProps, FunctionComponent } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import SubmitButton from "./SubmitButton";

type DeleteDialogProps = ComponentProps<
	typeof Dialog
> & {
	onClose: React.MouseEventHandler<HTMLButtonElement>,
	onDelete: React.MouseEventHandler<HTMLButtonElement>,
	inProgress: boolean
} & WrappedComponentProps;

const DeleteDialog: FunctionComponent<DeleteDialogProps> = ({
	onClose,
	onDelete,
	inProgress,
	intl,
	children,
	...dialogProps
}) => {
	return (
		<Dialog {...dialogProps}>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<SubmitButton 
					isLoading={inProgress}
					onClick={onDelete}
					color="warning"
				>
					{intl.formatMessage({ id: "app.Delete" })}
				</SubmitButton>
			</DialogActions>
		</Dialog>
	);
};

export default injectIntl(DeleteDialog);
